const address = {
  address: {
    pt: 'Endereço',
    en: 'Address',
    es: 'Dirección',
  },
  zip_code: {
    pt: 'CEP',
    en: 'Zip code',
    es: 'Código postal',
  },
  street: {
    pt: 'Rua',
    en: 'Street',
    es: 'Calle',
  },
  district: {
    pt: 'Bairro',
    en: 'District',
    es: 'Barrio',
  },
  city: {
    pt: 'Cidade',
    en: 'City',
    es: 'Ciudad',
  },
  state: {
    pt: 'Estado',
    en: 'State',
    es: 'Estado',
  },
  complement: {
    pt: 'Complemento',
    en: 'Complement',
    es: 'Complemento',
  },
  contact: {
    pt: 'Contato',
    en: 'Contact',
    es: 'Contacto',
  },
};

export default address;
