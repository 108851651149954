const status = {
  attended: {
    pt: 'Atendido',
    en: 'Achieved',
    es: 'Atendido',
  },
  finished: {
    pt: 'Concluído',
    en: 'Completed',
    es: 'Concluido',
  },
  cancelled: {
    pt: 'Cancelado',
    en: 'Canceled',
    es: 'Cancelado',
  },
  paralyzed: {
    pt: 'Paralisado',
    en: 'On hold',
    es: 'Paralizado',
  },
  in_progress: {
    pt: 'Em Andamento',
    en: 'In Progress',
    es: 'En curso',
  },
  not_pleated: {
    pt: 'Não pleiteado',
    en: 'Not attempted',
    es: 'No intentado',
  },
  not_applicable: {
    pt: 'Não aplicável',
    en: 'Not applicable',
    es: 'No aplicable',
  },
  suggestion: {
    pt: 'Não iniciado',
    en: 'Not started',
    es: 'No empezado',
  },
  not_attended: {
    pt: 'Não atendido',
    en: 'Not achieved',
    es: 'No atendido',
  },
  undefined: {
    pt: 'Não definido',
    en: 'Not set',
    es: 'No definido',
  },
  loading_chart: {
    pt: 'Carregando gráfico',
    en: 'Loading chart',
    es: 'Cargando gráfico',
  },
  analysis_pending: {
    pt: 'Análises pendentes',
    en: 'Pending analyzes',
    es: 'Análisis pendientes',
  },
  status_of_project: {
    pt: 'Status do projeto...',
    en: 'Project Status...',
    es: 'Estado del proyecto...',
  },
  action_plan_report: {
    pt: 'Relatório de Plano de Ação',
    en: 'Action Plan Report',
    es: 'Informe de Plan de Acción',
  },
  search_enterprises: {
    pt: 'Buscar empreendimentos...',
    en: 'Search a master project...',
    es: 'Buscar emprendimientos...',
  },
  status_not_completed: {
    pt: 'Não preenchido o campo de status',
    en: 'Status field not filled',
    es: 'El campo de estado no está lleno',
  },
};

export default status;
