/*eslint no-unused-expressions: off*/
import React, { FunctionComponent, useContext } from 'react';
import { Menu, Dropdown } from 'antd';
import { UserContext } from 'contexts/UserContext';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TopMenuContext } from 'contexts/TopMenuContext';
import {
  LogoutOutlined,
  SolutionOutlined,
  UserOutlined,
} from '@ant-design/icons';
import styles from './style.module.scss';

import pageLanguage from 'language';

const ProfileMenu: FunctionComponent<RouteComponentProps> = ({
  history: { replace, push },
}) => {
  const { user, logout } = useContext(UserContext);
  const { activeTopMenu, setActiveTopMenu } = useContext(TopMenuContext);
  const adminMenuAccess =
    user.role === 'asset_management_admin' ||
    user.role === 'certifications_admin' ||
    user.role === 'certifications_internal_user' ||
    user.role === 'consultant_jr';

  const exit = () => {
    setActiveTopMenu('user');
    logout();
    replace('/login_certificacoes');
  };

  if (!user) {
    return null;
  }

  const role = (userRole, userLanguage) => {
    const roles = {
      asset_management_user: pageLanguage.asset_management_user[userLanguage],
      asset_management_admin: pageLanguage.asset_management_admin[userLanguage],
      certifications_user:
        pageLanguage.certifications_external_user[userLanguage],
      certifications_internal_user:
        pageLanguage.certifications_internal_user[userLanguage],
      consultant_jr: pageLanguage.consultant_jr[userLanguage],
      certifications_admin: pageLanguage.certifications_admin[userLanguage],
      basic_user: pageLanguage.basic_user[userLanguage],
    };

    return roles[userRole] || '';
  };

  const menu = (
    <Menu selectable={false}>
      <Menu.Item key="user">
        <strong>
          {pageLanguage.welcome[user.language]}, {user.name}
        </strong>
      </Menu.Item>
      <Menu.Divider key="divider" />
      <Menu.Item key="user_role">
        <div>
          <strong>{pageLanguage.role[user.language]}: </strong>
          {role(user.role, user.language)}
        </div>
      </Menu.Item>
      <Menu.Item key="email">
        <div>
          <strong>Email: </strong>
          {user.email}
        </div>
      </Menu.Item>
      <Menu.Divider key="divider2" />
      <Menu.Item key="profile" onClick={() => push('/profile')}>
        <div>
          <UserOutlined className={`${styles.menuIcon}`} />
          {pageLanguage.profile[user.language]}
        </div>
      </Menu.Item>
      <Menu.Divider key="divider3" />
      {adminMenuAccess && (
        <Menu.Item
          key="administrative"
          onClick={() => {
            if (activeTopMenu === 'admin') {
              setActiveTopMenu('user');
              sessionStorage.setItem('menu', 'user');
              push('/projects');
            } else {
              sessionStorage.setItem('menu', 'admin');
              setActiveTopMenu('admin');
              push('/users');
            }
          }}
        >
          <SolutionOutlined className={`${styles.menuIcon}`} />
          {activeTopMenu === 'admin'
            ? pageLanguage.main_menu[user.language]
            : pageLanguage.admin[user.language]}
        </Menu.Item>
      )}
      <Menu.Divider key="divider4" />
      <Menu.Item key="logout" onClick={exit}>
        <div>
          <LogoutOutlined className={`${styles.menuIcon}`} />
          {pageLanguage.exit[user.language]}
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Dropdown overlay={menu} trigger={['click']}>
        <div className={styles.dropdown}>{user?.name}</div>
      </Dropdown>
    </div>
  );
};

export default withRouter(ProfileMenu);
