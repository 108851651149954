/*eslint no-unused-expressions: off*/
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';

import { ConfigProvider } from 'antd';
import ptBR from 'antd/es/locale/pt_BR';
import enUS from 'antd/es/locale/en_US';
import esES from 'antd/es/locale/es_ES';

import { UserContext } from 'contexts/UserContext';
import { Locale } from 'antd/lib/locale-provider';

const AntdConfigProvider: FunctionComponent = ({ children }) => {
  const [locale, setLocale] = useState<Locale>();

  const { language } = useContext(UserContext);

  useEffect(() => {
    if (language) {
      if (language === 'pt') {
        setLocale(ptBR);
      }
      if (language === 'en') {
        setLocale(enUS);
      }
      if (language === 'es') {
        setLocale(esES);
      }
    }
  }, [language]);

  return <ConfigProvider locale={locale}>{children}</ConfigProvider>;
};

export default AntdConfigProvider;
