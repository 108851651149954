/*eslint no-unused-expressions: off*/
import { Language } from 'interfaces/Language';
import React, {
  createContext,
  FunctionComponent,
  useState,
  useEffect,
} from 'react';
import { User } from '../interfaces/User';
import { NotificationConfig } from 'interfaces/NotificationConfig';

const notificationConfigDefault = {
  emailNotification: 0,
  notificationFrequency: [],
  projects: [],
  receiveNotificationAbout: [],
};

const UserContext = createContext<{
  user?: User;
  setUser: (user: User) => void;
  isAuthenticated: boolean;
  authenticate: (user: User, token: string) => void;
  logout: () => void;
  language: Language;
  setLanguage: (language: Language) => void;
  menuModal: boolean;
  setMenuModal: (isOpen: boolean) => void;
  token: string;
  notificationConfig: NotificationConfig;
  setNotificationConfig: (notificationConfig: NotificationConfig) => void;
  numberNotification: number;
  setNumberNotification: (numberNotification: number) => void;
  selectComponent: string;
  setSelectComponent: (selectComponent: string) => void;
}>({
  user: undefined,
  setUser: () => null,
  isAuthenticated: false,
  authenticate: () => null,
  logout: () => null,
  language: 'pt',
  setLanguage: () => null,
  menuModal: false,
  setMenuModal: () => null,
  token: '',
  notificationConfig: notificationConfigDefault,
  setNotificationConfig: () => null,
  numberNotification: 0,
  setNumberNotification: () => null,
  selectComponent: 'configuration',
  setSelectComponent: () => null,
});

const UserProvider: FunctionComponent<{}> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [menuModal, setMenuModal] = useState<boolean>(false);
  const [user, setUser] = useState<User>();
  const [token, setToken] = useState<string>('');
  const [language, setLanguage] = useState<'en' | 'pt' | 'es'>('pt');
  const [selectComponent, setSelectComponent] = useState('notification');
  const [
    notificationConfig,
    setNotificationConfig,
  ] = useState<NotificationConfig>(notificationConfigDefault);
  const [numberNotification, setNumberNotification] = useState(0);

  const authenticate = (userData: User, token: string) => {
    sessionStorage.setItem('@cte:user', JSON.stringify(userData));
    sessionStorage.setItem('@cte:token', token);

    setUser(userData);
    setIsAuthenticated(true);
    setToken(token);
    setLanguage(userData.language);
  };

  const logout = () => {
    sessionStorage.clear();
    setIsAuthenticated(false);
    setUser(undefined);
    setToken('');
  };

  useEffect(() => {
    const userStorage = sessionStorage.getItem('@cte:user');
    const token = sessionStorage.getItem('@cte:token');

    if (userStorage) {
      setUser(JSON.parse(userStorage));
      setToken(token);
      setIsAuthenticated(true);
      setLanguage(JSON.parse(userStorage).language);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        isAuthenticated,
        authenticate,
        logout,
        language,
        setLanguage,
        menuModal,
        setMenuModal,
        token,
        notificationConfig,
        setNotificationConfig,
        numberNotification,
        setNumberNotification,
        selectComponent,
        setSelectComponent,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
