/*eslint no-unused-expressions: off*/
import React, { FunctionComponent, useContext, useEffect } from 'react';
import { UserContext } from 'contexts/UserContext';
import styles from './style.module.scss';
import ProfileMenu from './ProfileMenu';
import LanguageSelector from './Language';
import { Row, Col } from 'antd';
import AlertNotification from './AlertNotification';
import { READ_ALL_NOTIFICATION } from './graphql';
import { useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';

import Ws from '@adonisjs/websocket-client';

const TopBar: FunctionComponent<{}> = () => {
  const { numberNotification, setNumberNotification } = useContext(UserContext);

  const { user } = useContext(UserContext);
  const { loading, data, refetch } = useQuery(READ_ALL_NOTIFICATION);

  const history = useHistory();

  useEffect(() => {
    const ws = Ws(process.env.REACT_APP_API_WS);

    const channelName = `notification:${user && user.id}`;

    const channel = ws.subscribe(channelName);

    ws.connect();

    channel.on('notification-event', (data) => {
      setNumberNotification(data);
    });

    return () => {
      channel.close();
      ws.close();
    };
  }, [user]);

  useEffect(() => {
    if (data) {
      const notificationNumber = data.getAllNotificationsByUser.filter(
        (notification) => notification.read === 0
      ).length;
      setNumberNotification(notificationNumber);
    }
  }, [data]);

  if (!user || loading) {
    return null;
  }

  return (
    <div className={styles.topbar}>
      <Row gutter={12}>
        <Col className={styles.alertContainer}>
          <div
            className={styles.notificationWrapper}
            onClick={() => history.push('/notification')}
          >
            <AlertNotification />
            {numberNotification !== 0 && (
              <p className={styles.notificationCount}>{numberNotification}</p>
            )}
          </div>
        </Col>
        <Col>
          <LanguageSelector />
        </Col>
        <Col>
          <ProfileMenu />
        </Col>
      </Row>
    </div>
  );
};

export default TopBar;
