const generalLabel = {
  //TYPE LABELS
  enter_project_name: {
    pt: 'Digite o nome do projeto',
    en: 'Enter the project name',
    es: 'Introduzca el nombre del proyecto',
  },
  error_without_project_name: {
    pt: 'Por favor, digite o nome do projeto',
    en: 'Please, enter the project name',
    es: 'Por favor, introduzca el nombre del proyecto',
  },
  delete_all_files: {
    pt: 'Deletar todos os arquivos',
    en: 'Delete all files',
    es: 'Eliminar todos los archivos',
  },
  configure_notifications: {
    pt: 'Configurar notificações',
    en: 'Configure notifications',
    es: 'Configurar notificaciones',
  },
  notification: {
    pt: 'Notificação',
    en: 'Notification',
    es: 'Notificación',
  },
  configuration: {
    pt: 'Configuração',
    en: 'Configuration',
    es: 'Configuración',
  },
  send_notification: {
    pt: 'Enviar notificação',
    en: 'Send notification',
    es: 'Enviar notificación',
  },
  title: {
    pt: 'Título',
    en: 'Title',
    es: 'Título',
  },
  project: {
    pt: 'Projeto',
    en: 'Project',
    es: 'Proyecto',
  },
  users: {
    pt: 'Usuários',
    en: 'Users',
    es: 'Usuarios',
  },
  send: {
    pt: 'Enviar',
    en: 'Send',
    es: 'Mandar',
  },
  project_manager: {
    pt: 'Resposável',
    en: 'Manager',
    es: 'Responsable',
  },
  message: {
    pt: 'Mensagem',
    en: 'Message',
    es: 'Mensaje',
  },
  requirement_type: {
    pt: 'Tipo do requisito',
    en: 'Requirement type',
    es: 'Tipo de requisito',
  },
  type_item: {
    pt: 'Tipo do item',
    en: 'Type of item',
    es: 'Tipo do ítem',
  },
  object_type: {
    pt: 'Tipo de objeto',
    en: 'Type of object',
    es: 'Tipo de objeto',
  },
  indicators: {
    pt: 'Indicadores',
    en: 'Indicators',
    es: 'Indicadores',
  },
  list: {
    pt: 'Lista',
    en: 'List',
    es: 'Lista',
  },
  graph: {
    pt: 'Gráfico',
    en: 'Graphic',
    es: 'Gráfico',
  },
  object_types: {
    pt: 'Tipos de objeto',
    en: 'Object Types',
    es: 'Tipos de objetos',
  },
  target_type: {
    pt: 'Tipo de alvo',
    en: 'Target type',
    es: 'Tipo de objetivo',
  },
  service_type: {
    pt: 'Tipo de serviço',
    en: 'Service type',
    es: 'Tipo de servicio',
  },
  company_type: {
    pt: 'Tipo de empresa',
    en: 'Company type',
    es: 'Tipo de compañías',
  },
  field_type: {
    pt: 'Tipo de campo',
    en: 'Type of field',
    es: 'Tipo de campo',
  },
  text_type: {
    pt: 'Tipo de texto',
    en: 'Text type',
    es: 'Tipo de texto',
  },
  measurer_type: {
    pt: 'Tipo de medidores',
    en: 'Measurer type',
    es: 'Tipo de medidores',
  },
  measurer_types: {
    pt: 'Tipos de medidores',
    en: 'Measurer types',
    es: 'Tipos de medidores',
  },
  measurement_type: {
    pt: 'Tipo de medição',
    en: 'Measurement type',
    es: 'Tipo de medida',
  },

  notification_frequency: {
    pt: 'Frequência de notificação',
    en: 'Notification frequency',
    es: 'Frecuencia de notificación',
  },

  monday: {
    pt: 'Segunda-Feira',
    en: 'Monday',
    es: 'Lunes',
  },
  tuesday: {
    pt: 'Terça-Feira',
    en: 'Tuesday',
    es: 'Martes',
  },
  wednesday: {
    pt: 'Quarta-Feira',
    en: 'Wednesday',
    es: 'Miércoles',
  },
  thursday: {
    pt: 'Quinta-Feira',
    en: 'Thursday',
    es: 'Jueves',
  },
  friday: {
    pt: 'Sexta-Feira',
    en: 'Friday',
    es: 'Viernes',
  },
  saturday: {
    pt: 'Sábado',
    en: 'Saturday',
    es: 'Sábado',
  },
  sunday: {
    pt: 'Domingo',
    en: 'Sunday',
    es: 'Domingo',
  },
  daily: {
    pt: 'Diariamente',
    en: 'Daily',
    es: 'Diariamente',
  },
  technicalAnalysisComments: {
    pt: 'Análises técnicas pendentes',
    en: 'Pending technical analyses',
    es: 'Análisis técnico pendiente',
  },
  actionPlanItemStatusChange: {
    pt: 'Mudança de status de itens plano de ação',
    en: 'Change in action plan item status',
    es: 'Cambio de estado de elementos del plan de acción',
  },
  newUnreadComments: {
    pt: 'Novos comentários não lidos',
    en: 'New unread comments',
    es: 'Nuevos comentarios no leídos',
  },
  projectInformationChanges: {
    pt: 'Alterações nas informações no projeto',
    en: 'Changes in project information',
    es: 'Cambios en la información del proyecto',
  },
  commentEditing: {
    pt: 'Edição de comentários',
    en: 'Comment editing',
    es: 'Edición de comentarios',
  },
  commentDeletion: {
    pt: 'Exclusão de comentários',
    en: 'Comment deletion',
    es: 'Eliminación de comentarios',
  },
  pendingApprovalComments: {
    pt: 'Comentários pendentes de aprovação',
    en: 'Pending approval comments',
    es: 'Comentarios pendientes de aprobación',
  },
  pendingApprovalTechnicalAnalysis: {
    pt: 'Análises técnicas pendentes de aprovação',
    en: 'Pending approval technical analysis',
    es: 'Análisis técnico pendiente de aprobación',
  },

  // NAME LABELS
  receiveNotificationAbout: {
    pt: 'Receber notificação sobre',
    en: 'Receive notification about',
    es: 'Recibir notificación sobre',
  },
  email_notification: {
    pt: 'Notificação por email',
    en: 'Email notification',
    es: 'Notificación por correo electrónico',
  },
  email_notification_accept: {
    pt: 'Me envie notificação',
    en: 'Send me a notification',
    es: 'Envíame una notificación',
  },
  email_notification_refused: {
    pt: 'Não me envie notificação',
    en: 'Do not send me a notification',
    es: 'No me envíes notificaciones',
  },
  email_notification_projects: {
    pt: 'Receber notificações somente dos projetos:',
    en: 'Receive notifications only from projects',
    es: 'Recibir notificaciones solo de proyectos',
  },
  chapter_name: {
    pt: 'Nome do capítulo',
    en: 'Chapter name',
    es: 'Nombre del capítulo',
  },
  form_of_service_name: {
    pt: 'Nome da forma de atendimento',
    en: 'Name of form of service',
    es: 'Nombre de forma de servicio',
  },
  company_name: {
    pt: 'Nome fantasia',
    en: 'Company name',
    es: 'Nombre fantasía',
  },
  service_name: {
    pt: 'Nome do serviço',
    en: 'Service name',
    es: 'Nombre del servicio',
  },
  project_name: {
    pt: 'Nome do projeto',
    en: 'Project name',
    es: 'Nombre del proyecto',
  },
  name_level_certification: {
    pt: 'Nome do nível de certificação',
    en: 'Name of certification level',
    es: 'Nombre del nivel de certificación',
  },
  report_name: {
    pt: 'Nome do projeto no relatório',
    en: 'Project name in report',
    es: 'Nombre del proyecto en el informe',
  },
  report_title: {
    pt: 'Título do relatório',
    en: 'Report title',
    es: 'Título del informe',
  },
  developed_by: {
    pt: 'Desenvolvido por',
    en: 'Developed by',
    es: 'Desarrollado por',
  },
  report_of: {
    pt: 'Relatório de',
    en: 'Report of',
    es: 'Informe de',
  },
  report_include_dashboard: {
    pt: 'Incluir dashboard',
    en: 'Include dashboard',
    es: 'Incluir tablero',
  },
  office: {
    pt: 'Cargo/Função',
    en: 'Office',
    es: 'Posición/Función',
  },
  trading_name: {
    pt: 'Razão social',
    en: 'Trading name',
    es: 'Razón social',
  },
  asset_owner: {
    pt: 'Proprietário do ativo',
    en: 'Owner',
    es: 'Propietario del activo',
  },
  academic_formation: {
    pt: 'Formação acadêmica',
    en: 'Field of study',
    es: 'Formación académica',
  },

  // REPORT LABELS
  cte_report: {
    pt: 'CTE - Relatório',
    en: 'CTE - Report',
    es: 'CTE - Reporte',
  },
  status_report: {
    pt: 'Relatório de status',
    en: 'Status report',
    es: 'Reporte de estado',
  },
  report_diagnostic: {
    pt: 'Relatório de Diagnóstico',
    en: 'Diagnostic Report',
    es: 'Informe de diagnóstico',
  },
  diagnostic_report: {
    pt: 'Relatório de diagnóstico',
    en: 'Diagnostic report',
    es: 'Reporte de diagnóstico',
  },
  action_plans_report: {
    pt: 'Relatório de planos de ação',
    en: 'Action plans report',
    es: 'Reporte de planes de acción',
  },

  // INFORMATION LABELS
  technical_informations: {
    pt: 'Informações técnicas',
    en: 'Technical information',
    es: 'Informaciones técnicas',
  },
  principal_informations: {
    pt: 'Informações principais',
    en: 'Main informations',
    es: 'Informaciones principales',
  },
  enterprise_informations: {
    pt: 'Informações do empreendimento',
    en: 'Enterprise informations',
    es: 'Información de emprendimiento',
  },
  service_informations: {
    pt: 'Informações do serviço',
    en: 'Service informations',
    es: 'Información de servicio',
  },
  base_requirements: {
    pt: 'Requisitos BASE',
    en: 'BASE requirements',
    es: 'Requisitos BASE',
  },
  summary_by_chapter: {
    pt: 'Resumo por capítulo',
    en: 'Summary by chapter',
    es: 'Resumen por capítulo',
  },
  project_punctuation_summary: {
    pt: 'Dashboard do projeto',
    en: 'Project dashboard',
    es: 'Tablero del proyecto',
  },
  main_menu: {
    pt: 'Menu principal',
    en: 'Main menu',
    es: 'Menu principal',
  },
  datasheet: {
    pt: 'Ficha técnica',
    en: 'Datasheet',
    es: 'Ficha técnica',
  },

  // IMAGE LABELS
  project_image: {
    pt: 'Imagem do projeto',
    en: 'Project image',
    es: 'Imagen del proyecto',
  },
  image_service: {
    pt: 'Imagem do serviço',
    en: 'Service image',
    es: 'Imagen del servicio',
  },
  enterprise_image: {
    pt: 'Imagem do empreendimento',
    en: 'Image',
    es: 'Imagen del desarrollo',
  },

  // OPTION LABELS
  more_options: {
    pt: 'Mais opções',
    en: 'More options',
    es: 'Más opciones',
  },
  options_group: {
    pt: 'Grupo de opções de serviço',
    en: 'Service option group',
    es: 'Grupo de opciones de servicio',
  },
  evolutionary_service_option: {
    pt: 'Opção evolutiva de atendimento',
    en: 'Evolutionary service option',
    es: 'Opción de servicio evolutivo',
  },
  service_option: {
    pt: 'Opção de atendimento',
    en: 'Service option',
    es: 'Opción de servicio',
  },
  service_options: {
    pt: 'Opções de atendimento',
    en: 'Service options',
    es: 'Opciones de servicio',
  },
  form_of_service_option: {
    pt: 'Opção da forma de atendimento',
    en: 'Service form option',
    es: 'Opción de formulario de servicio',
  },

  // LOGS LABELS
  access_log: {
    pt: 'Log de acessos',
    en: 'Log of access',
    es: 'Registro de accesos',
  },
  acess_history: {
    pt: 'Histórico de acesso',
    en: 'Access history',
    es: 'Historial de acceso',
  },
  last_access: {
    pt: 'Último acesso',
    en: 'Last access',
    es: 'Último acceso',
  },
  access_quantity: {
    pt: 'Quantidade de acessos',
    en: 'Access quantity',
    es: 'Numero de accesos',
  },
  status_quantity: {
    pt: 'Quantidade de status',
    en: 'Status quantity',
    es: 'Cantidad de status',
  },
  quantity_of: {
    pt: 'Quantidade de',
    en: 'Quantity of',
    es: 'Cantidad de',
  },
  target_quantity: {
    pt: 'Quantidade do alvo',
    en: 'Target quantity',
    es: 'Cantidad objetivo',
  },
  last_comment_of: {
    pt: 'Último comentário de',
    en: 'Last comment of',
    es: 'Último comentario de',
  },
  data_list: {
    pt: 'Lista de dados',
    en: 'Data list',
    es: 'Lista de datos',
  },
  upload_date: {
    pt: 'Data de upload',
    en: 'Upload date',
    es: 'Fecha de upload',
  },

  // ACTION PLANS LABELS
  action_plan: {
    pt: 'Plano de ação',
    en: 'Action plan',
    es: 'Plan de acción',
  },
  action_plans: {
    pt: 'Planos de ação',
    en: 'Action plans',
    es: 'Plan de acción',
  },
  fields_action_plan: {
    pt: 'Campos do plano de ação',
    en: 'Fields of the action plan',
    es: 'Campos del plan de acción',
  },
  action_plans_fields: {
    pt: 'Campos do plano de ação',
    en: 'Fields of the action plan',
    es: 'Campos del plan de acción',
  },
  title_action_plan: {
    pt: 'Status - Plano de Ação',
    en: 'Status - Action Plan',
    es: 'Status - Plano de Ação - Plan de acción',
  },
  action_plan_status_per_designer: {
    pt: 'Status das ações por disciplina',
    en: 'Action plan status by discipline',
    es: 'Estado de las acciones por disciplina',
  },
  action_plan_progress: {
    pt: 'Andamento Geral das Ações',
    en: 'General progress of actions',
    es: 'Progreso general de acciones',
  },

  // SERVICE LABELS
  service_member: {
    pt: 'Integrante do serviço',
    en: 'Service member',
    es: 'Miembro del servicio',
  },
  service_form: {
    pt: 'Forma de atendimento',
    en: 'Form of service',
    es: 'Forma de servicio',
  },
  form_of_service: {
    pt: 'Forma de Atendimento',
    en: 'Form of Service',
    es: 'Forma de Servicio',
  },
  form_of_services: {
    pt: 'Formas de atendimento',
    en: 'Forms of service',
    es: 'Formas de servicio',
  },
  service_structure: {
    pt: 'Estrutura de serviço',
    en: 'Service structure',
    es: 'Estructura de servicio',
  },
  last_update: {
    pt: 'Última atualização',
    en: 'Last update',
    es: 'Última actualización',
  },
  service_details: {
    pt: 'Detalhes do serviço',
    en: 'Project details',
    es: 'detalles del proyecto',
  },
  service_indicators: {
    pt: 'Indicadores do serviço',
    en: 'Service indicators',
    es: 'Indicadores de servicio',
  },
  auto_save: {
    pt: 'Salvamento automático',
    en: 'Autosave',
    es: 'Autoguardado',
  },
  auto_save_disabled: {
    pt: 'Salvamento automático desabilitado',
    en: 'autosave disabled',
    es: 'autoguardado deshabilitado',
  },
  auto_save_enabled: {
    pt: 'Salvamento automático habilitado',
    en: 'Autosave enabled',
    es: 'Autoguardado habilitado',
  },

  // SCORE LABELS
  evolutionary_punctuation: {
    pt: 'Pontuação evolutiva',
    en: 'Evolutionary punctuation',
    es: 'Puntuación evolutiva',
  },
  form_of_service_score: {
    pt: 'Pontuação da forma de atendimento',
    en: 'Score of form of service',
    es: 'Puntuación de forma de servicio',
  },
  initial_points: {
    pt: 'Pontuação inicial',
    en: 'Initial points',
    es: 'Puntos iniciales',
  },
  final_points: {
    pt: 'Pontuação final',
    en: 'Final points',
    es: 'Puntos finales',
  },
  score_status: {
    pt: 'Status da pontuação',
    en: 'Score status',
    es: 'Estado de puntuación',
  },
  total_score: {
    pt: 'Pontuação total',
    en: 'Total score',
    es: 'Puntaje total',
  },
  score_to_reach: {
    pt: 'Pontos para atingir',
    en: 'minimum score range',
    es: 'Puntos para alcanzar',
  },

  // LEVELS LABELS
  certification_level: {
    pt: 'Níveis de certificação',
    en: 'Certification levels',
    es: 'Niveles de certificación',
  },
  intervention_level_one: {
    pt: 'Intervenção nível 1',
    en: 'Intervention 1',
    es: 'Intervención de nivel 1',
  },
  intervention_level_two: {
    pt: 'Intervenção nível 2',
    en: 'Intervention 2 ',
    es: 'Intervención de nivel 2',
  },
  intervention_level: {
    pt: 'Nível de intervenção',
    en: 'Credit Status',
    es: 'Nível de Intervención',
  },

  // ANALYSIS LABELS
  cte_analysis: {
    pt: 'Análise CTE',
    en: 'CTE analysis',
    es: 'Análisis CTE',
  },
  technical_analysis: {
    pt: 'Análise Técnica',
    en: 'Technical analysis',
    es: 'Análisis técnico',
  },
  technical_analysis_of: {
    pt: 'Análise Técnica de',
    en: 'Technical Analysis of',
    es: 'Análisis técnico de',
  },
  project_analysis: {
    pt: 'Análise do projeto',
    en: 'Project analysis',
    es: 'Análisis de proyectos',
  },
  measurement_sources: {
    pt: 'Fontes de medição',
    en: 'Measurement sources',
    es: 'Fuentes de medida',
  },
  enterprise_details: {
    pt: 'Detalhes do empreendimento',
    en: 'Enterprise details',
    es: 'Detalles de emprendimiento',
  },
  current_password: {
    pt: 'Senha atual',
    en: 'Current password',
    es: 'Contraseña actual',
  },
  project_status_summary: {
    pt: 'Status do projeto',
    en: 'Project status',
    es: 'Estado del proyecto',
  },
  chapter_status: {
    pt: 'Status do capítulo',
    en: 'Chapter status',
    es: 'Status do capítulo',
  },

  // USERS LABELS
  basic_user: {
    pt: 'Usuário básico',
    en: 'Basic User',
    es: 'Usuario basico',
  },
  certifications_admin: {
    pt: 'Administrador de Certificações',
    en: 'Certification Admin',
    es: 'Administrador de certificaciones',
  },
  certifications_user: {
    pt: 'Usuário de Certificações',
    en: 'Certification User',
    es: 'Usuario de certificaciones',
  },
  certifications_manager: {
    pt: 'Gestor de Certificação',
    en: 'Certification Manager',
    es: 'Gerente de Certificación',
  },
  certifications_external_user: {
    pt: 'Usuário externo',
    en: 'External consultant',
    es: 'Consultor externo',
  },
  certifications_internal_user: {
    pt: 'Consultor interno',
    en: 'Internal consultant',
    es: 'Consultor interno',
  },
  asset_management_admin: {
    pt: 'Administrador de Gestão de Ativos',
    en: 'Asset Management Admin',
    es: 'Administrador de gestión de activos',
  },
  asset_management_user: {
    pt: 'Usuário de Gestão de Ativos',
    en: 'Asset Management User',
    es: 'Usuario de gestión de activos',
  },

  //OTHER LABELS
  public_company: {
    pt: 'Empresa pública',
    en: 'Public company',
    es: 'Compañía pública',
  },
  private_company: {
    pt: 'Empresa privada',
    en: 'Private company',
    es: 'Compañía privada',
  },
  father_object: {
    pt: 'Objeto superior',
    en: 'Top object',
    es: 'Objeto superior',
  },
  multi_select: {
    pt: 'Seleção múltipla',
    en: 'Multiselect',
    es: 'Selección múltiple',
  },
  text_area: {
    pt: 'Texto em área',
    en: 'Textarea',
    es: 'Texto en área',
  },
  pending_comments: {
    pt: 'Comentários pendentes',
    en: 'Pending Comments',
    es: 'Comentarios pendientes',
  },
  customizable_fields: {
    pt: 'Campos personalizáveis',
    en: 'Customizable fields',
    es: 'Campos personalizables',
  },
  customizable_columns: {
    pt: 'Colunas personalizáveis',
    en: 'Customizable columns',
    es: 'Columnas personalizables',
  },
  good_practice_goal: {
    pt: 'Meta de boas práticas',
    en: 'Good practice goal',
    es: 'Objetivo de buenas prácticas',
  },
  best_practice_goal: {
    pt: 'Meta de melhores práticas',
    en: 'Better practice goal',
    es: 'Objetivo de mejores prácticas',
  },
  certification_Levels: {
    pt: 'Este serviço possui Níveis de Certificações',
    en: 'This service has Certification Levels',
    es: 'Este servicio cuenta con Niveles de Certificación',
  },
};

export default generalLabel;
