import 'rc-drawer/assets/index.css';
/*eslint no-unused-expressions: off*/
import React, { useState, FunctionComponent, useContext } from 'react';
import DrawerMenu from 'rc-drawer';
import { LayoutContext } from 'contexts/LayoutContext';
import MenuLeft from './MenuLeft';

const AppMenu: FunctionComponent = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);

  const { isMobileView } = useContext(LayoutContext);

  const toggleOpen = () => {
    document
      .querySelector('#root')
      .setAttribute(
        'style',
        !isMobileMenuOpen ? 'overflow: hidden; width: 100%; height: 100%;' : ''
      );

    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  if (isMobileView) {
    return (
      <DrawerMenu
        getContainer={null}
        level={null}
        open={isMobileMenuOpen}
        onMaskClick={toggleOpen}
        onHandleClick={toggleOpen}
        className="drawer-light"
      >
        <MenuLeft />
      </DrawerMenu>
    );
  }

  return <MenuLeft />;
};

export default AppMenu;
