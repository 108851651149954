/*eslint no-unused-expressions: off*/
import React, { FunctionComponent } from 'react'
import classNames from 'classnames'
import styles from './style.module.scss'

const Loader: FunctionComponent<{
    spinning?: boolean
    fullScreen?: boolean
}> = ({ spinning, fullScreen }) => (
    <div
        className={classNames(styles.loader, {
            [styles.hidden]: !spinning,
            [styles.fullScreen]: fullScreen,
        })}
    />
)

Loader.defaultProps = {
    spinning: true,
    fullScreen: false
}

export default Loader
