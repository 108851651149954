const properties = {
  business: {
    pt: 'Negócios',
    en: 'Business Center',
    es: 'Centro de Negocios',
  },
  household: {
    pt: 'Domiciliar',
    en: 'Apartment',
    es: 'Residencias y Apartamentos',
  },
  corporate: {
    pt: 'Corporativo',
    en: 'Corporate Building',
    es: 'Edificio corporativo',
  },
  warehouse: {
    pt: 'Galpão',
    en: 'Warehouse',
    es: 'Almacén',
  },
  residential: {
    pt: 'Residencial',
    en: 'Residential',
    es: 'Residential',
  },
  arena: {
    pt: 'Arena ou centro esportivo',
    en: 'Stadium/Sport Arena',
    es: 'Estadio/Arena Deportiva',
  },
  bank: {
    pt: 'Banco¹',
    en: 'Financial Office',
    es: 'Oficina Bancaria',
  },
  bank2: {
    pt: 'Banco²',
    en: 'Bank',
    es: 'Sucursal del Banco',
  },
  activity_center: {
    pt: 'Centro de atividades, lazer e turismo',
    en: 'Convention Center, Entertainment, Social/Meeting',
    es: 'Centro de convenciones/ espectáculos / Reuniones y encuentros',
  },
  religious_center: {
    pt: 'Centro religioso',
    en: 'Religious Worship',
    es: 'Culto religioso/Adoración Religiosa',
  },
  educational: {
    pt: 'Educacional',
    en: 'Learning Space/Educational Center',
    es: 'Espacio de aprendizaje/ Educación',
  },
  medical_clinic: {
    pt: 'Clínica médica/diagnóstico',
    en: 'Healthcare: Clinic/ Outpatient Office (Diagnostic)',
    es: 'Clínica médica/ Centro diagnóstico',
  },
  data_center: {
    pt: 'Data center',
    en: 'Data center',
    es: 'Data center',
  },
  hospital: {
    pt: 'Hospital',
    en: 'Hospital',
    es: 'Hospital(es)',
  },
  hotel_or_resort: {
    pt: 'Hotel/Resort',
    en: 'Hotel/Resort',
    es: 'Hotel/Resort',
  },
  industrial: {
    pt: 'Industrial',
    en: 'Industrial Manufacturing',
    es: 'Producción industrial',
  },
  laboratory: {
    pt: 'Laboratório',
    en: 'Laboratory',
    es: 'Laboratorio(s)',
  },
  shop: {
    pt: 'Loja',
    en: 'Retail Store',
    es: 'Establecimiento de venta/Comercio',
  },
  museum: {
    pt: 'Museu',
    en: 'Museum',
    es: 'Museo',
  },
  public_building: {
    pt: 'Prédio público',
    en: 'Government Office/Public Order Center',
    es: 'Oficina del gobierno/ Departamentos gubernamentales',
  },
  restaurant: {
    pt: 'Restaurante',
    en: 'Restaurant/Cafeteria/Fast Food',
    es: 'Restaurante',
  },
};

export default properties;
