/*eslint no-unused-expressions: off*/
import React from 'react';
import { BackTop, Layout } from 'antd';
import classNames from 'classnames';
import TopBar from 'components/LayoutComponents/TopBar';
import Menu from 'components/LayoutComponents/Menu';
import './styles.scss';

class MainLayout extends React.PureComponent {
  render() {
    return (
      <Layout
        className={classNames({
          settings__borderLess: false,
          settings__squaredBorders: false,
          settings__fixedWidth: false,
          settings__menuShadow: false,
          settings__menuTop: false,
        })}
      >
        <BackTop />
        <Menu />
        <Layout>
          <Layout.Header>
            <TopBar />
          </Layout.Header>
          <Layout.Content
            style={{
              height: '100%',
              position: 'relative',
            }}
          >
            <div className="Layout__contentWrapper">
              <div className="Layout__content">{this.props.children}</div>
            </div>
          </Layout.Content>
        </Layout>
      </Layout>
    );
  }
}

export default MainLayout;
