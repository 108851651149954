const actions = {
  //SIMPLE ACTIONS
  search: {
    pt: 'Buscar',
    en: 'Search',
    es: 'Buscar',
  },
  reset: {
    pt: 'Resetar',
    en: 'Reset',
    es: 'Reiniciar',
  },
  edit: {
    pt: 'Editar',
    en: 'Edit',
    es: 'Editar',
  },
  exit: {
    pt: 'Sair',
    en: 'Exit',
    es: 'Sal',
  },
  remove: {
    pt: 'Remover',
    en: 'Remove',
    es: 'Eliminar',
  },
  remove_value: {
    pt: 'Remover Valor',
    en: 'Remove Value',
    es: 'Eliminar Valor',
  },
  remove_indicator: {
    pt: 'Remover Indicador',
    en: 'Remove Indicator',
    es: 'Quitar indicador',
  },
  exclude: {
    pt: 'Excluir',
    en: 'Remove',
    es: 'Eliminar',
  },
  delete: {
    pt: 'Deletar',
    en: 'Delete',
    es: 'Borrar',
  },
  go_back: {
    pt: 'Voltar',
    en: 'Go back',
    es: 'Vuelve',
  },
  save: {
    pt: 'Salvar',
    en: 'Save',
    es: 'Guardar',
  },
  create: {
    pt: 'Criar',
    en: 'Create',
    es: 'Crear',
  },
  close: {
    pt: 'Fechar',
    en: 'Close',
    es: 'Cerrar',
  },
  download: {
    pt: 'Baixar',
    en: 'Download',
    es: 'Descargar',
  },
  approve: {
    pt: 'Aprovar',
    en: 'Approve',
    es: 'Aprobar',
  },
  cancel: {
    pt: 'Cancelar',
    en: 'Cancel',
    es: 'Anular',
  },
  add: {
    pt: 'Adicionar',
    en: 'Add',
    es: 'Agregar',
  },
  add_indicator: {
    pt: 'Adicionar Indicador',
    en: 'Add Indicator',
    es: 'Agregar indicador',
  },
  add_value: {
    pt: 'Adicionar Valor',
    en: 'Add Value',
    es: 'Añadir Valor',
  },
  view: {
    pt: 'Visualizar',
    en: 'View',
    es: 'Para ver',
  },
  duplicate: {
    pt: 'Duplicar',
    en: 'Duplicate',
    es: 'Duplicar',
  },
  send: {
    pt: 'Enviar',
    en: 'Send',
    es: 'Enviar',
  },
  apply: {
    pt: 'Aplicar',
    en: 'Apply',
    es: 'Aplicar',
  },

  //ADD ACTIONS
  add_inheritance: {
    pt: 'Adicionar herança',
    en: 'Add inheritance',
    es: 'Añadir herencia',
  },
  add_action_plan: {
    pt: 'Adicionar plano de ação',
    en: 'Add action plan',
    es: 'Añadir plan de acción',
  },
  add_requirement: {
    pt: 'Adicionar requisito',
    en: 'Add requirement',
    es: 'Añadir requisito',
  },
  add_status: {
    pt: 'Adicionar status',
    en: 'Add status',
    es: 'Añadir status',
  },
  add_category: {
    pt: 'Adicionar categoria',
    en: 'Add category',
    es: 'Añadir categoría',
  },
  add_certificate: {
    pt: 'Adicionar certificado',
    en: 'Add certificate',
    es: 'Añadir certificado',
  },
  add_chapter: {
    pt: 'Adicionar capítulo',
    en: 'Add chapter',
    es: 'Añadir capítulo',
  },
  add_new_form_of_service: {
    pt: 'Adicionar forma de serviço',
    en: 'Add type of attention',
    es: 'Añadir forma de atención',
  },
  add_line: {
    pt: 'Adicionar linha',
    en: 'Add line',
    es: 'Añadir línea',
  },
  add_form_of_service: {
    pt: 'Adicionar forma de atendimento',
    en: 'Add type of attention',
    es: 'Añadir forma de atención',
  },
  add_options_group: {
    pt: 'Adicionar grupo de opção',
    en: 'Add option group',
    es: 'Añadir grupo de opciones',
  },
  add_service_option: {
    pt: 'Adicionar opção de atendimento',
    en: 'Add service option',
    es: 'Añadir opción de servicio',
  },
  add_project: {
    pt: 'Adicionar projeto',
    en: 'Add project',
    es: 'Añadir proyecto',
  },
  add_service_member: {
    pt: 'Adicionar integrante do serviço',
    en: 'Add service member',
    es: 'Añadir miembro del servicio',
  },
  add_service: {
    pt: 'Adicionar serviço',
    en: 'Add service',
    es: 'Añadir servicio',
  },
  add_new_contact: {
    pt: 'Adicionar novo contato',
    en: 'Add new contact',
    es: 'Añadir nuevo contacto',
  },

  // REMOVE ACTIONS
  remove_inheritance: {
    pt: 'Remover herança',
    en: 'Remove inheritance',
    es: 'Borrar herencia',
  },
  remove_action_plan: {
    pt: 'Remover plano de ação',
    en: 'Remove action plan',
    es: 'Borrar plan de acción',
  },
  remove_chapter: {
    pt: 'Remover capítulo',
    en: 'Remove chapter',
    es: 'Borrar capítulo',
  },
  remove_requirement: {
    pt: 'Remover requisito',
    en: 'Remove requirement',
    es: 'Borrar requisito',
  },
  remove_category: {
    pt: 'Remover categoria',
    en: 'Remove category',
    es: 'Borrar categoría',
  },
  remove_status: {
    pt: 'Remover status',
    en: 'Remove status',
    es: 'Borrar status',
  },
  remove_certificate: {
    pt: 'Remover certificado',
    en: 'Remove certificate',
    es: 'Borrar certificado',
  },
  delete_form_of_service: {
    pt: 'Remover forma de serviço',
    en: 'Remove type of attention',
    es: 'Borrar forma de atención',
  },
  remove_service_option: {
    pt: 'Remover opção de atendimento',
    en: 'Remove service option',
    es: 'Borrar opción de servicio',
  },
  remove_line: {
    pt: 'Remover linha',
    en: 'Remove line',
    es: 'Borrar línea',
  },
  remove_form_of_service: {
    pt: 'Remover forma de atendimento',
    en: 'Remove type of attention',
    es: 'Borrar forma de atención',
  },
  remove_options_group: {
    pt: 'Remover grupo de opção',
    en: 'Remove option group',
    es: 'Eliminar grupo de opciones',
  },
  remove_service_member: {
    pt: 'Remover integrante do serviço',
    en: 'Remove service member',
    es: 'Borrar miembro del servicio',
  },
  remove_service: {
    pt: 'Remover serviço',
    en: 'Remove service',
    es: 'Borrar servicio',
  },
  remove_contact: {
    pt: 'Remover contato acima',
    en: 'Remove contact',
    es: 'Eliminar contacto arriba',
  },
  delete_selected_records: {
    pt: 'Deletar selecionados',
    en: 'Delete selected',
    es: 'Borrar seleccionado',
  },

  // EDIT ACTIONS
  edit_status_group: {
    pt: 'Editar grupo de status',
    en: 'Edit group of status',
    es: 'Editar grupo de status',
  },
  edit_user: {
    pt: 'Editar Usuário',
    en: 'Edit User',
    es: 'Editar Usuario',
  },
  edit_column: {
    pt: 'Editar campo',
    en: 'Edit field',
    es: 'Editar campo',
  },
  edit_design: {
    pt: 'Editar projetista',
    en: 'Edit designer',
    es: 'Editar diseñador',
  },
  edit_service: {
    pt: 'Editar serviço',
    en: 'Edit service',
    es: 'Editar servicio',
  },
  edit_indicators: {
    pt: 'Editar indicador',
    en: 'Edit indicator',
    es: 'Editar indicador',
  },
  edit_object_type: {
    pt: 'Editar tipo de objeto',
    en: 'Edit type of object',
    es: 'Editar tipo de objeto',
  },
  edit_customizable_column: {
    pt: 'Editar coluna personalizável',
    en: 'Edit customizable column',
    es: 'Editar columna personalizable',
  },
  edit_record: {
    pt: 'Editar registro',
    en: 'Edit record',
    es: 'Editar registro',
  },
  edit_profile: {
    pt: 'Editar perfil',
    en: 'Edit profile',
    es: 'Editar perfil',
  },
  edit_project: {
    pt: 'Editar projeto',
    en: 'Edit project',
    es: 'Editar proyecto',
  },
  edit_report: {
    pt: 'Editar relatório',
    en: 'Edit report',
    es: 'Editar informe',
  },
  edit_form_of_service: {
    pt: 'Editar forma de atendimento',
    en: 'Edit User',
    es: 'Editar forma de atención',
  },
  edit_chapter: {
    pt: 'Editar capítulo',
    en: 'Edit chapter',
    es: 'Editar capítulo',
  },
  edit_customizable_field: {
    pt: 'Editar campo personalizável',
    en: 'Edit customizable field',
    es: 'Editar campo personalizable',
  },
  edit_enterprise: {
    pt: 'Editar empreendimento',
    en: 'Edit a master project',
    es: 'Editar emprendimiento',
  },
  alter_password: {
    pt: 'Alterar senha',
    en: 'Update password',
    es: 'Alterar contraseña',
  },

  // CREATE ACTIONS
  create_design: {
    pt: 'Criar projetista',
    en: 'Create designer',
    es: 'Crear diseñador',
  },
  create_indicator: {
    pt: 'Novo indicador',
    en: 'New indicator',
    es: 'Nuevo indicador',
  },
  create_object_type: {
    pt: 'Criar tipo de objeto',
    en: 'Create type of object',
    es: 'Crear tipo de objeto',
  },
  new_customizable_column: {
    pt: 'Criar coluna personalizável',
    en: 'Create customizable column',
    es: 'Crear columna personalizable',
  },
  create_report: {
    pt: 'Criar relatório',
    en: 'Create report',
    es: 'Crear informe',
  },
  create_enterprise: {
    pt: 'Criar empreendimento',
    en: 'Create a master project',
    es: 'Crear emprendimiento',
  },
  new_customizable_field: {
    pt: 'Criar campo personalizável',
    en: 'Create customizable field',
    es: 'Crear campo personalizable',
  },

  //SELECT ACTIONS
  select_column: {
    pt: 'Selecionar coluna',
    en: 'Select column',
    es: 'Seleccionar columna',
  },
  select_file: {
    pt: 'Selecionar arquivo',
    en: 'Select file',
    es: 'Seleccione archivo',
  },
  to_select: {
    pt: 'Selecionar',
    en: 'Select',
    es: 'Seleccionar',
  },

  // SHOW ACTIONS
  show_fields: {
    pt: 'Mostrar campos',
    en: 'Show fields',
    es: 'Revelar campos',
  },
  open_analysis: {
    pt: 'Abrir Diagnóstico',
    en: 'Open Assessment',
    es: 'Abrir Diagnóstico',
  },

  // CLOSE ACTIONS
  close_analysis: {
    pt: 'Fechar Diagnóstico',
    en: 'Close Assessment',
    es: 'Cerrar Diagnóstico',
  },
  hide_fields: {
    pt: 'Ocultar campos',
    en: 'Hide fields',
    es: 'Esconder los campos',
  },

  //SEARCH ACTIONS
  search_projects: {
    pt: 'Buscar projeto',
    en: 'Search project',
    es: 'Buscar proyecto',
  },
  filter_by: {
    pt: 'Filtrar por',
    en: 'Filter by',
    es: 'Filtrar por',
  },

  //DOWNLOAD ACTIONS
  generate_pdf: {
    pt: 'Gerar Relatório',
    en: 'Generate Report',
    es: 'Generar Reporte',
  },
  download_zip: {
    pt: 'Baixar zip',
    en: 'Download zip',
    es: 'Descargar zip',
  },

  //OTHER ACTIONS
  approve_comments: {
    pt: 'Aprovar comentários',
    en: 'Approve Comments',
    es: 'Aprobar comentarios',
  },
  duplicate_service: {
    pt: 'Duplicar serviço',
    en: 'Duplicate service',
    es: 'Servicio duplicado',
  },
  manage_columns: {
    pt: 'Gerenciar colunas',
    en: 'Manage columns',
    es: 'Administrar columnas',
  },
  click_to_upload: {
    pt: 'Clique para upload',
    en: 'Click to upload',
    es: 'Haga clic para subir',
  },
};

export default actions;
