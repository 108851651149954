/*eslint no-unused-expressions: off*/
import React, { FunctionComponent } from 'react';
import { Typography } from 'antd';

import { StyledTitleProps } from './interfaces';

const { Title } = Typography;

const StyledTitle: FunctionComponent<StyledTitleProps> = ({
  style = {},
  level,
  className = '',
  children,
}) => {
  return (
    <Title
      style={{ margin: 0, whiteSpace: 'normal', ...style }}
      level={level}
      className={className}
    >
      {children}
    </Title>
  );
};

export default StyledTitle;
