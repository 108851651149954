/*eslint no-unused-expressions: off*/
import React, { FunctionComponent } from 'react';
import styles from '../style.module.scss';
import { ReactComponent as Alert } from '../../../../assets/icons/AlertHeader.svg';

const AlertNotification: FunctionComponent<{}> = () => {

  return (
    <div className={styles.alertContainer}>
      <Alert className={styles.alertIcon} />
    </div>
  );
};

export default AlertNotification;
