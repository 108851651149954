import React, { createContext, useState } from 'react';

type IndicatorsContextType = {
  attData: boolean;
  setAttData: (value: boolean) => void;
};

const defaultValues: IndicatorsContextType = {
  attData: false,
  setAttData: () => {},
};

export const IndicatorsContext = createContext<IndicatorsContextType>(
  defaultValues
);

type IndicatorsProviderProps = {
  children: React.ReactNode;
};

export const IndicatorsProvider: React.FC<IndicatorsProviderProps> = ({
  children,
}) => {
  const [attData, setAttData] = useState<boolean>(false);

  const contextValue: IndicatorsContextType = {
    attData,
    setAttData,
  };

  return (
    <IndicatorsContext.Provider value={contextValue}>
      {children}
    </IndicatorsContext.Provider>
  );
};
