/*eslint no-unused-expressions: off*/
import React, { useState, useContext, useEffect } from 'react';
import { Modal, Typography, Row, Col } from 'antd';

import { UserContext } from 'contexts/UserContext';

import './styles.scss';
import { LayoutContext } from 'contexts/LayoutContext';
import StyledTitle from 'components/AntComponents/StyledTitle';

const ContactModal = ({ description, title }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const { isMobileView } = useContext(LayoutContext);
  const { menuModal, setMenuModal } = useContext(UserContext);

  useEffect(() => {
    if (menuModal) {
      setVisible(true);
    }
  }, [menuModal]);

  const close = (): void => {
    setVisible(false);
    setMenuModal(false);
  };

  return (
    <Modal
      visible={visible}
      closable
      onCancel={close}
      title={<StyledTitle level={2}>{title}</StyledTitle>}
      width={isMobileView ? '100%' : 500}
      centered
      destroyOnClose
      footer={null}
    >
      <Row gutter={24}>
        <Col span={24}>
          <Typography.Paragraph className="description">
            {description}
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Typography.Paragraph className="email">
            suporte@plus.com.br
          </Typography.Paragraph>
        </Col>
      </Row>
    </Modal>
  );
};

export default ContactModal;
