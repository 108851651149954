const common = {
  minutes_ago: {
    pt: 'minutos atrás',
    en: 'minutes ago',
    es: 'minutos hace',
  },
  hours_ago: {
    pt: 'horas atrás',
    en: 'hours ago',
    es: 'horas hace',
  },
  days_ago: {
    pt: 'dias atrás',
    en: 'days ago',
    es: 'días hace',
  },
  changes_made: {
    pt: 'fez alterações no projeto',
    en: 'made changes to the project',
    es: 'realizó cambios en el proyecto',
  },
  sent_email: {
    pt: 'enviou um e-mail para você',
    en: 'sent an email to you',
    es: 'envió un correo electrónico a usted',
  },
  yes: {
    pt: 'Sim',
    en: 'Yes',
    es: 'Si',
  },
  no: {
    pt: 'Não',
    en: 'No',
    es: 'No',
  },
  and: {
    pt: 'E',
    en: 'And',
    es: 'Y',
  },
  or: {
    pt: 'Ou',
    en: 'Or',
    es: 'O',
  },
  of: {
    pt: 'de',
    en: 'of',
    es: 'en',
  },
  to: {
    pt: 'a',
    en: 'to',
    es: 'a',
  },
  item: {
    pt: 'Item',
    en: 'Item',
    es: 'Ítem',
  },
  all: {
    pt: 'Todos',
    en: 'All',
    es: 'Todos',
  },
  simple_goal: {
    pt: 'Meta',
    en: 'Goal',
    es: 'Objetivo',
  },
  goal: {
    pt: 'Meta/Total',
    en: 'Goal/Total',
    es: 'Meta/Total',
  },
  goal_uppercase: {
    pt: 'META',
    en: 'GOAL',
    es: 'META',
  },
  not_goal_uppercase: {
    pt: 'NÃO META',
    en: 'NOT GOAL',
    es: 'NO META',
  },
  indicators_class: {
    pt: 'Classe Indicador',
    en: 'Indicator Class',
    es: 'Clase indicador',
  },
  description_class: {
    pt: 'Descrição da classe',
    en: 'Class description',
    es: 'Descripción de la clase',
  },
  name: {
    pt: 'Nome',
    en: 'Name',
    es: 'Nombre',
  },
  class_name: {
    pt: 'Nome da classe',
    en: 'Class name',
    es: 'Nombre de la clase',
  },
  field_measurement: {
    pt: 'Unidade de medida',
    en: 'Unit of measurement',
    es: 'Unidad de medida',
  },
  chart_title: {
    pt: 'Título do Gráfico',
    en: 'Chart Title',
    es: 'Título del Gráfico',
  },
  indicator_name: {
    pt: 'Nome do indicador',
    en: 'Indicator name',
    es: 'Nombre del indicador',
  },
  indicator_list_preview: {
    pt: 'Preview da lista',
    en: 'List preview',
    es: 'Vista previa de la lista',
  },
  indicator_value: {
    pt: 'Valor do indicador',
    en: 'Indicator value',
    es: 'Valor del indicador',
  },
  indicator_list_name: {
    pt: 'Nome do indicador da lista',
    en: 'Indicator list name',
    es: 'Nombre del indicador de lista',
  },
  class_description: {
    pt: 'Descrição da classe',
    en: 'Class description',
    es: 'Descripción de la clase',
  },
  number: {
    pt: 'Número',
    en: 'Number',
    es: 'Número',
  },
  service: {
    pt: 'Serviço',
    en: 'Service',
    es: 'Servicio',
  },
  services: {
    pt: 'Serviços',
    en: 'Services',
    es: 'Servicios',
  },
  comment: {
    pt: 'Comentário',
    en: 'Comment',
    es: 'Comentario',
  },
  comments: {
    pt: 'Comentários',
    en: 'Comments',
    es: 'Comentarios',
  },
  comment_of: {
    pt: 'Comentário de',
    en: 'Comment of',
    es: 'Comentario de',
  },
  edited_by: {
    pt: 'Editado por',
    en: 'Edited by',
    es: 'Editado por',
  },
  deleted_by: {
    pt: 'Excluído por',
    en: 'Deleted_by',
    es: 'Eliminado por',
  },
  responsible: {
    pt: 'Responsável',
    en: 'Responsible',
    es: 'Responsable',
  },
  informations: {
    pt: 'Informações',
    en: 'Informations',
    es: 'Información',
  },
  measurers: {
    pt: 'Medidores',
    en: 'Measurers',
    es: 'Metros',
  },
  columns: {
    pt: 'Colunas',
    en: 'Columns',
    es: 'Columnas',
  },
  dashboard: {
    pt: 'Dashboard',
    en: 'Dashboard',
    es: 'Tablero',
  },
  suport: {
    pt: 'Suporte',
    en: 'Suport',
    es: 'Soporte',
  },
  telephone: {
    pt: 'Telefone',
    en: 'Phone',
    es: 'Teléfono',
  },
  cellphone: {
    pt: 'Celular',
    en: 'Mobile phone',
    es: 'Móvil',
  },
  email: {
    pt: 'Email',
    en: 'Email',
    es: 'Email',
  },
  date: {
    pt: 'Data',
    en: 'Date',
    es: 'Fecha',
  },
  upload: {
    pt: 'Upload',
    en: 'Upload',
    es: 'Upload',
  },
  uploadBy: {
    pt: 'Upload por:',
    en: 'Upload by:',
    es: 'Upload por:',
  },
  author: {
    pt: 'Autor',
    en: 'Author',
    es: 'Autor',
  },
  actions: {
    pt: 'Ações',
    en: 'Actions',
    es: 'Acciones',
  },
  profile: {
    pt: 'Perfil',
    en: 'Profile',
    es: 'Perfil',
  },
  group: {
    pt: 'Grupo',
    en: 'Group',
    es: 'Grupo',
  },
  points: {
    pt: 'Pontos',
    en: 'Points',
    es: 'Puntos',
  },
  score: {
    pt: 'Pontuação',
    en: 'Score',
    es: 'Puntuación',
  },
  description: {
    pt: 'Descrição',
    en: 'Description',
    es: 'Descripción',
  },
  certification: {
    pt: 'Certificação',
    en: 'Certification',
    es: 'Certificación',
  },
  category: {
    pt: 'Categoria',
    en: 'Category',
    es: 'Categoría',
  },
  indicators: {
    pt: 'Indicadores',
    en: 'Indicators',
    es: 'Indicadores',
  },
  target: {
    pt: 'Target',
    en: 'Target',
    es: 'Target',
  },
  metric: {
    pt: 'Métrica',
    en: 'Metric',
    es: 'Métrico',
  },
  intense: {
    pt: 'Intenso',
    en: 'Intense',
    es: 'Intenso',
  },
  absolute: {
    pt: 'Absoluto',
    en: 'Absolute',
    es: 'Absoluto',
  },
  code: {
    pt: 'Código',
    en: 'Code',
    es: 'Código',
  },
  filters: {
    pt: 'Filtros',
    en: 'Filters',
    es: 'Filtros',
  },
  legends: {
    pt: 'Legendas',
    en: 'Legends',
    es: 'Subtítulos',
  },
  typology: {
    pt: 'Tipologia',
    en: 'Project Type',
    es: 'Tipología',
  },
  initials: {
    pt: 'Sigla',
    en: 'Initials',
    es: 'Sigla',
  },
  initials_drawer: {
    pt: 'Sigla',
    en: 'Acronym',
    es: 'Iniciales',
  },
  color: {
    pt: 'Cor',
    en: 'Color',
    es: 'Color',
  },
  green: {
    pt: 'Verde',
    en: 'Green',
    es: 'Verde',
  },
  yellow: {
    pt: 'Amarelo',
    en: 'Yellow',
    es: 'Amarillo',
  },
  orange: {
    pt: 'Laranja',
    en: 'Orange',
    es: 'Naranja',
  },
  gray: {
    pt: 'Cinza',
    en: 'Gray',
    es: 'Gris',
  },
  silver: {
    pt: 'Prata',
    en: 'Silver',
    es: 'Plata',
  },
  blue: {
    pt: 'Azul',
    en: 'Blue',
    es: 'Azul',
  },
  red: {
    pt: 'Vermelho',
    en: 'Red',
    es: 'Rojo',
  },
  attachments: {
    pt: 'Anexos',
    en: 'Attachments',
    es: 'Anexos',
  },
  background: {
    pt: 'Fundo',
    en: 'Background',
    es: 'Fondo',
  },
  client: {
    pt: 'Cliente/PM/Const.',
    en: 'Client/PM/General Contractor',
    es: 'Cliente/PM/Constructor',
  },
  manager: {
    pt: 'Gestor',
    en: 'Manager',
    es: 'Gerente',
  },
  designer: {
    pt: 'Projetista',
    en: 'Designer',
    es: 'Diseñador',
  },
  designers: {
    pt: 'Projetistas',
    en: 'Designers',
    es: 'Diseñadores',
  },
  consultant: {
    pt: 'Consultor',
    en: 'Consultant',
    es: 'Consultante',
  },
  consultant_jr: {
    pt: 'Analista',
    en: 'Analyst',
    es: 'Analista',
  },
  consultant_jr2: {
    pt: 'Integrante da consultoria',
    en: 'Consulting member',
    es: 'Miembro consultor',
  },
  status: {
    pt: 'Status',
    en: 'Status',
    es: 'Status',
  },
  type: {
    pt: 'Tipo',
    en: 'Type',
    es: 'Tipo',
  },
  option: {
    pt: 'Opção',
    en: 'Option',
    es: 'Opción',
  },
  text: {
    pt: 'Texto',
    en: 'Text',
    es: 'Texto',
  },
  chapter: {
    pt: 'Capítulo',
    en: 'Chapter',
    es: 'Capítulo',
  },
  select: {
    pt: 'Seleção',
    en: 'Select',
    es: 'Selección',
  },
  position: {
    pt: 'Cargo',
    en: 'Job title',
    es: 'Cargo',
  },
  function: {
    pt: 'Função',
    en: 'Function',
    es: 'Ocupación',
  },
  functions: {
    pt: 'Funções',
    en: 'Functions',
    es: 'Funciones',
  },
  language: {
    pt: 'Língua',
    en: 'Language',
    es: 'Idioma',
  },
  label: {
    pt: 'Rótulo',
    en: 'ID Code',
    es: 'Etiqueta',
  },
  warning: {
    pt: 'Aviso',
    en: 'Warning',
    es: 'Advertencia',
  },
  energy: {
    pt: 'Energia',
    en: 'Energy',
    es: 'Energía',
  },
  water: {
    pt: 'Água',
    en: 'Water',
    es: 'Agua',
  },
  carbon: {
    pt: 'Carbono',
    en: 'Carbon',
    es: 'Carbón',
  },
  gas: {
    pt: 'Gás',
    en: 'Gas',
    es: 'Gas',
  },
  others: {
    pt: 'Outros',
    en: 'Others',
    es: 'Otros',
  },
  users: {
    pt: 'Usuários',
    en: 'Users',
    es: 'Usuarios',
  },
  line: {
    pt: 'Linha',
    en: 'Line',
    es: 'Línea',
  },
  liters: {
    pt: 'Litros',
    en: 'Liters',
    es: 'Litros',
  },
  source: {
    pt: 'Fonte',
    en: 'Source',
    es: 'Fuente',
  },
  project: {
    pt: 'Projeto',
    en: 'Project',
    es: 'Proyecto',
  },
  projects: {
    pt: 'Projetos',
    en: 'Projects',
    es: 'Proyectos',
  },
  record: {
    pt: 'Registro',
    en: 'Record',
    es: 'Registro',
  },
  records: {
    pt: 'Registros',
    en: 'Records',
    es: 'Registros',
  },
  credit: {
    pt: 'Crédito',
    en: 'Credit',
    es: 'Crédito',
  },
  company: {
    pt: 'Empresa',
    en: 'Company',
    es: 'Compañía',
  },
  companies: {
    pt: 'Empresas',
    en: 'Companies',
    es: 'Compañías',
  },
  file: {
    pt: 'Arquivo',
    en: 'File',
    es: 'Archivo',
  },
  files: {
    pt: 'Arquivos',
    en: 'Files',
    es: 'Archivos',
  },
  deleteAll: {
    pt: 'Deletar tudo',
    en: 'Delete all',
    es: 'Borrar todo',
  },
  downloadAll: {
    pt: 'Baixar tudo',
    en: 'Download all',
    es: 'Descargar todo',
  },
  downloadSelected: {
    pt: 'Baixar selecionados',
    en: 'Download selected',
    es: 'Descargar seleccionado',
  },
  allFiles: {
    pt: 'Arquivos do projeto',
    en: 'Project files',
    es: 'Archivos de proyecto',
  },
  team: {
    pt: 'Equipe',
    en: 'Team',
    es: 'Equipo',
  },
  role: {
    pt: 'Permissão',
    en: 'Role',
    es: 'Permiso',
  },
  title: {
    pt: 'Título',
    en: 'Title',
    es: 'Título',
  },
  class_a: {
    pt: 'Classe A',
    en: 'Class A',
    es: 'Clase A',
  },
  class_b: {
    pt: 'Classe B',
    en: 'Class B',
    es: 'Clase B',
  },
  class_c: {
    pt: 'Classe C',
    en: 'Class C',
    es: 'Clase C',
  },
  class_d: {
    pt: 'Classe D',
    en: 'Class D',
    es: 'Clase D',
  },
  leed: {
    pt: 'LEED',
    en: 'LEED',
    es: 'LEED',
  },
  aqua: {
    pt: 'AQUA',
    en: 'AQUA',
    es: 'AQUA',
  },
  simple: {
    pt: 'Simples',
    en: 'Simple',
    es: 'Simple',
  },
  leed_summary: {
    pt: 'LEED Resumido',
    en: 'Summary LEED',
    es: 'LEED Resumido',
  },
  sub_function: {
    pt: 'Sub-função',
    en: 'Sub-function',
    es: 'Sub función',
  },
  requirement: {
    pt: 'Requisito',
    en: 'Requirement',
    es: 'Requisito',
  },
  requirements: {
    pt: 'Requisitos',
    en: 'Requirements',
    es: 'Requisitos',
  },
  report: {
    pt: 'Relatório',
    en: 'Report',
    es: 'Informe',
  },
  reports: {
    pt: 'Relatórios',
    en: 'Reports',
    es: 'Reportes',
  },
  print: {
    pt: 'Imprimir',
    en: 'Print',
    es: 'Imprimir',
  },
  certificate: {
    pt: 'Certificado',
    en: 'Certificate',
    es: 'Certificado',
  },
  enterprise: {
    pt: 'Empreendimento',
    en: 'Master project',
    es: 'Empreendimento',
  },
  enterprises: {
    pt: 'Empreendimentos',
    en: 'Master projects',
    es: 'Emprendimientos',
  },
  admin: {
    pt: 'Administrativo',
    en: 'Administrative',
    es: 'Administrativo',
  },
  incorporator: {
    pt: 'Incorporadora',
    en: 'Incorporator',
    es: 'Desarrollador',
  },
  prerequisites: {
    pt: 'Pré-requisitos',
    en: 'Prerequisites',
    es: 'Prerrequisitos',
  },
  mandatory: {
    pt: 'Mandatório',
    en: 'Mandatory',
    es: 'Obligatorio',
  },
  not_mandatory: {
    pt: 'Não Mandatório',
    en: 'Not Mandatory',
    es: 'No es Obligatorio',
  },
  prerequisite: {
    pt: 'Pré-requisito',
    en: 'Prerequisite',
    es: 'Prerrequisito',
  },
  action_type: {
    pt: 'Classificação',
    en: 'Classification',
    es: 'Clasificación',
  },
  construction_company: {
    pt: 'Construtora',
    en: 'Construction company',
    es: 'Compañía de construcción',
  },
  action_plans: {
    pt: 'Planos de ação',
    en: 'Action plans',
    es: 'Plan de acción',
  },
  clear_all: {
    pt: 'Limpar todos',
    en: 'Delete all',
    es: 'Eliminar todo',
  },
};

export default common;
