const newItems = {
  new_status: {
    pt: 'Novo status',
    en: 'New status',
    es: 'Nuevo status',
  },
  new_user: {
    pt: 'Novo Usuário',
    en: 'New User',
    es: 'Nuevo Usuario',
  },
  addUserButton: {
    pt: ' Novo Usuário',
    en: ' New User',
    es: ' Nuevo Usuario',
  },
  new_designer: {
    pt: 'Novo projetista',
    en: 'New designer',
    es: 'Nuevo diseñador',
  },
  new_status_group: {
    pt: 'Novo grupo de status',
    en: 'New group of status',
    es: 'Nuevo grupo de status',
  },
  new_report: {
    pt: 'Novo relatório',
    en: 'New report',
    es: 'Nuevo informe',
  },
  new_service: {
    pt: 'Novo serviço',
    en: 'New service',
    es: 'Nuevo servicio',
  },
  new_indicators: {
    pt: 'Novo indicador',
    en: 'New indicator',
    es: 'Nuevo indicador',
  },
  new_object_type: {
    pt: 'Novo tipo de objeto',
    en: 'New type of object',
    es: 'Nuevo tipo de objeto',
  },
  new_file: {
    pt: 'Novo arquivo',
    en: 'New file',
    es: 'Nuevo archivo',
  },
  new_enterprise: {
    pt: 'Novo empreendimento',
    en: 'New master project',
    es: 'Nuevo emprendimiento',
  },
  new_field: {
    pt: 'Novo campo',
    en: 'New field',
    es: 'Nuevo campo',
  },
  add_customizable_column: {
    pt: 'Novo campo personalizável',
    en: 'New customizable field',
    es: 'Nuevo campo personalizable',
  },
  new_measurer_type: {
    pt: 'Novo tipo de medidor',
    en: 'New measurer type',
    es: 'Nuevo tipo de medidor',
  },
  new_source: {
    pt: 'Nova fonte',
    en: 'New source',
    es: 'Nueva fuente',
  },
  new_record: {
    pt: 'Novo registro',
    en: 'New record',
    es: 'Nuevo registro',
  },
  new_service_indicators: {
    pt: 'Novos indicadores do serviço',
    en: 'New project indicators',
    es: 'Nuevos indicadores de proyecto',
  },
  new_customizable_column: {
    pt: 'Nova coluna personalizável',
    en: 'New customizable column',
    es: 'Nueva columna personalizable',
  },
  new_password: {
    pt: 'Nova senha',
    en: 'New password',
    es: 'Nueva contraseña',
  },
  add_form_of_service: {
    pt: 'Nova forma de atendimento',
    en: 'New type of attention',
    es: 'Nueva forma de atención',
  },
  new_form_of_service: {
    pt: 'Nova forma de atendimento',
    en: 'New type of attention',
    es: 'Nueva forma de atención',
  },
  new_company: {
    pt: 'Nova empresa',
    en: 'New company',
    es: 'Nueva compañía',
  },
  new_project: {
    pt: 'Novo projeto',
    en: 'New project',
    es: 'Nuevo proyecto',
  },
};

export default newItems;
