/*eslint no-unused-expressions: off*/
import React, {
  createContext,
  FunctionComponent,
  useState,
  useEffect,
} from 'react';

const TopMenuContext = createContext<{
  activeTopMenu: 'admin' | 'user';
  setActiveTopMenu: (activeTopMenu: 'admin' | 'user') => void;
}>({
  activeTopMenu: 'user',
  setActiveTopMenu: () => null,
});

const TopMenuProvider: FunctionComponent<{}> = ({ children }) => {
  const [activeTopMenu, setActiveTopMenu] = useState<'admin' | 'user'>('user');

  useEffect(() => {
    const menu = sessionStorage.getItem('menu');
    const userStorage = JSON.parse(sessionStorage.getItem('@cte:user'));
    if (menu === 'admin' && userStorage.role === 'certifications_admin') {
      setActiveTopMenu('admin');
    } else {
      setActiveTopMenu('user');
    }

    if (!menu) {
      sessionStorage.setItem('menu', 'user');
    }
  }, []);

  return (
    <TopMenuContext.Provider
      value={{
        activeTopMenu,
        setActiveTopMenu,
      }}
    >
      {children}
    </TopMenuContext.Provider>
  );
};

export { TopMenuContext, TopMenuProvider };
