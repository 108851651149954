/*eslint no-unused-expressions: off*/
import React from 'react';
import { Layout } from 'antd';
import { withRouter } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import styles from './style.module.scss';
import './reset.scss'
@withRouter
class LoginLayout extends React.PureComponent {
  state = {
    backgroundNumber: 1,
    backgroundEnabled: true,
  };

  changeBackground = () => {
    const { backgroundNumber } = this.state;
    this.setState({
      backgroundEnabled: true,
      backgroundNumber: backgroundNumber === 5 ? 1 : backgroundNumber + 1,
    });
  };

  toggleBackground = () => {
    const { backgroundEnabled } = this.state;
    this.setState({
      backgroundEnabled: !backgroundEnabled,
    });
  };

  render() {
    const { children } = this.props;
    const { backgroundEnabled } = this.state;

    return (
      <Layout>
        <Layout.Content>
          <div
            className={
              backgroundEnabled &&
              `notImage ${styles.light} ${styles.overlay}`
            }
          >
            {backgroundEnabled && (
              <LazyLoadImage
                className={`filterImage lazy-image`}
                effect="blur"
                style={{ objectFit: 'cover', width: '100vw', height: '100vh' }}
                placeholderSrc={'/resources/images/login-background-2.jpg'}
                src={'/resources/images/login-background-2.jpg'}
              />
            )}

            <div className={styles.content}>{children}</div>
          </div>
        </Layout.Content>
      </Layout>
    );
  }
}

export default LoginLayout;
