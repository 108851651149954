const validationHelper = {
  atx_lines: {
    pt: 'Requisitos',
    en: 'Lines',
    es: 'Líneas',
  },
  atx_chapters: {
    pt: 'Capítulos',
    en: 'Chapters',
    es: 'Capítulos',
  },
  atx_options_group: {
    pt: 'Grupo de opções',
    en: 'Options group',
    es: 'Grupo de opciones',
  },
  atx_service_options: {
    pt: 'Opções de atendimento',
    en: 'Service options',
    es: 'Opciones de atención',
  },
  atx_form_of_services: {
    pt: 'Formas de atendimento',
    en: 'Forms of services',
    es: 'Formas de atención',
  },
  atx_form_of_service_option: {
    pt: 'Opção de forma de atendimento evolutiva',
    en: 'Form of service option',
    es: 'Opción de forma de atención evolutiva',
  },
  forms_of_service_id: {
    pt: 'Forma de atendimento',
    en: 'Form of service',
    es: 'Forma de atención',
  },
  leed_lines: {
    pt: 'Requisitos',
    en: 'Lines',
    es: 'Líneas',
  },
  leed_chapters: {
    pt: 'Capítulos',
    en: 'Chapters',
    es: 'Capítulos',
  },
  status_group_id: {
    pt: 'Status',
    en: 'Status',
    es: 'Status',
  },
  leed_lines_score: {
    pt: 'Pontuação',
    en: 'Score',
    es: 'Puntuación',
  },
  leed_action_plans: {
    pt: 'Planos de ação',
    en: 'Action plans',
    es: 'Planos de acción',
  },
  leed_service_options: {
    pt: 'Opções de atendimento',
    en: 'Service options',
    es: 'Opciones de servicio',
  },
  leed_form_of_services: {
    pt: 'Formas de atendimento',
    en: 'Forms of services',
    es: 'Formas de servicio',
  },
  certificates: {
    pt: 'Níveis de certificação',
    en: 'Certification levels',
    es: 'Niveles de certificación',
  },
  points_min: {
    pt: 'Pontuação inicial',
    en: 'Initial points',
    es: 'Puntos iniciales',
  },
  points_max: {
    pt: 'Pontuação final',
    en: 'Final points',
    es: 'Puntos finales',
  },
};

export default validationHelper;
