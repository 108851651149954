/*eslint no-unused-expressions: off*/
import React, { FunctionComponent, lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';
import Loader from './components/LayoutComponents/Loader';
import Route from './components/Route';

import IndexLayout from './layouts';
import NotFoundPage from './pages/404';

const routes = [
  // System Pages
  {
    path: '/login_certificacoes',
    component: lazy(() => import('./pages/login')),
    exact: true,
  },
  {
    path: '/register',
    component: lazy(() => import('./pages/register')),
    exact: true,
  },
  {
    path: '/set-password/:token',
    component: lazy(() => import('./pages/set-password')),
    exact: true,
  },
  {
    path: '/password-success',
    component: lazy(() => import('./pages/password-success')),
    exact: true,
  },
  {
    path: '/forgot-password',
    component: lazy(() => import('./pages/forgot-password')),
    exact: true,
  },
  {
    path: '/recover-success',
    component: lazy(() => import('./pages/recover-success')),
    exact: true,
  },

  // Dashboards
  {
    path: '/',
    exact: true,
    component: lazy(() => import('./pages/dashboard')),
    roles: ['certifications_admin'],
  },

  {
    path: '/users',
    exact: true,
    component: lazy(() => import('./pages/users')),
    roles: [
      'asset_management_admin',
      'certifications_admin',
      'certifications_internal_user',
      'consultant_jr',
    ],
  },

  {
    path: '/users/designers',
    exact: true,
    component: lazy(() => import('./pages/users/designers')),
    roles: ['asset_management_admin', 'certifications_admin'],
  },

  {
    path: '/object-hierarchies/indicators',
    exact: true,
    component: lazy(() => import('./pages/object-hierarchies/indicators')),
    roles: ['asset_management_admin', 'certifications_admin'],
  },

  {
    path: '/object-hierarchies/object-types',
    exact: true,
    component: lazy(() => import('./pages/object-hierarchies/object-types')),
    roles: ['asset_management_admin', 'certifications_admin'],
  },

  {
    path: '/object-hierarchies/customizable-fields',
    exact: true,
    component: lazy(
      () => import('./pages/object-hierarchies/customizable-fields')
    ),
    roles: ['asset_management_admin', 'certifications_admin'],
  },

  // Enterprises
  {
    path: '/enterprises',
    exact: true,
    component: lazy(() => import('./pages/enterprises/enterprises')),
    roles: [
      'asset_management_admin',
      'certifications_admin',
      'asset_management_user',
      'certifications_internal_user',
    ],
  },
  {
    path: '/enterprises/companies',
    exact: true,
    component: lazy(() => import('./pages/enterprises/companies')),
    roles: [
      'asset_management_admin',
      'certifications_admin',
      'certifications_internal_user',
      'consultant_jr',
    ],
  },
  {
    path: '/service-value/:projectServiceId/atx/consultant',
    exact: true,
    component: lazy(
      () => import('./pages/enterprises/enterprises/Services/ProjectServices')
    ),
    roles: [
      'asset_management_admin',
      'certifications_admin',
      'certifications_user',
      'asset_management_user',
      'certifications_internal_user',
    ],
  },
  {
    path: '/service-value/:projectServiceId/atx/designer',
    exact: true,
    component: lazy(
      () => import('./pages/enterprises/enterprises/Services/ProjectServices')
    ),
    roles: [
      'asset_management_admin',
      'certifications_admin',
      'certifications_user',
      'asset_management_user',
      'certifications_internal_user',
    ],
  },

  {
    path: '/service-value/:projectServiceId/atx/consultant_jr',
    exact: true,
    component: lazy(
      () => import('./pages/enterprises/enterprises/Services/ProjectServices')
    ),
    roles: [
      'asset_management_admin',
      'certifications_admin',
      'certifications_user',
      'asset_management_user',
      'consultant_jr',
      'certifications_internal_user',
    ],
  },

  {
    path: '/service-value/:projectServiceId/atx/client',
    exact: true,
    component: lazy(
      () => import('./pages/enterprises/enterprises/Services/ProjectServices')
    ),
    roles: [
      'asset_management_admin',
      'certifications_admin',
      'certifications_user',
      'asset_management_user',
      'consultant_jr',
      'certifications_internal_user',
    ],
  },
  {
    path: '/service-value/:projectServiceId/atx/manager',
    exact: true,
    component: lazy(
      () => import('./pages/enterprises/enterprises/Services/ProjectServices')
    ),
    roles: ['certifications_admin', 'certifications_internal_user'],
  },
  {
    path: '/profile',
    exact: true,
    component: lazy(() => import('./pages/profile')),
    roles: [
      'asset_management_admin',
      'certifications_admin',
      'asset_management_user',
      'certifications_user',
      'consultant_jr',
      'certifications_internal_user',
    ],
  },
  {
    path: '/services',
    exact: true,
    component: lazy(() => import('./pages/services')),
    roles: ['asset_management_admin', 'certifications_admin'],
  },
  {
    path: '/services/create',
    exact: true,
    component: lazy(() => import('./pages/services/Create')),
    roles: ['asset_management_admin', 'certifications_admin'],
  },
  {
    path: '/services/:serviceId',
    exact: true,
    component: lazy(() => import('./pages/services/Update')),
    roles: ['asset_management_admin', 'certifications_admin'],
  },
  {
    path: '/projects',
    exact: true,
    component: lazy(() => import('./pages/projects')),
    roles: [
      'asset_management_admin',
      'certifications_admin',
      'certifications_user',
      'certifications_internal_user',
      'consultant_jr',
    ],
  },
  {
    path: '/status',
    exact: true,
    component: lazy(() => import('./pages/services/status')),
    roles: ['asset_management_admin', 'certifications_admin'],
  },
  {
    path: '/customizable-fields',
    exact: true,
    component: lazy(() => import('./pages/services/customizable-columns')),
    roles: ['asset_management_admin', 'certifications_admin'],
  },
  {
    path: '/notification',
    exact: true,
    component: lazy(() => import('./pages/notification')),
  },

  //USULES ROUTES
  // {
  //   path: '/login_ativos',
  //   component: lazy(() => import('./pages/login')),
  //   exact: true,
  // },
  // {
  //   path: '/login_contratos',
  //   component: lazy(() => import('./pages/login')),
  //   exact: true,
  // },
  // {
  //   path: '/reports',
  //   exact: true,
  //   component: lazy(() => import('./pages/reports')),
  //   roles: ['asset_management_admin', 'certifications_admin'],
  // },

  // {
  //   path: '/meters/customizable-columns',
  //   exact: true,
  //   component: lazy(
  //     () => import('./pages/enterprises/meters/CustomizableColumns')
  //   ),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'asset_management_user',
  //   ],
  // },
  // {
  //   path: '/enterprises/:id/records',
  //   exact: true,
  //   component: lazy(() => import('./pages/enterprises/records')),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'asset_management_user',
  //   ],
  // },
  // {
  //   path: '/enterprises/:enterpriseId/records/:id',
  //   exact: true,
  //   component: lazy(() => import('./pages/enterprises/record-readings')),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'asset_management_user',
  //   ],
  // },
  // {
  //   path: '/enterprises/:enterpriseId/records/:id/files',
  //   exact: true,
  //   component: lazy(() => import('./pages/enterprises/records/record-files')),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'asset_management_user',
  //     'asset_management_user',
  //   ],
  // },
  // {
  //   path: '/service-value/:id/atx/consultant_jr',
  //   exact: true,
  //   component: lazy(
  //     () =>
  //       import(
  //         './pages/enterprises/enterprises/Services/ConsultantJunior/ATXService'
  //       )
  //   ),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'certifications_user',
  //     'asset_management_user',
  //   ],
  // },

  // {
  //   path: '/service-value/:id/atx/indicators',
  //   exact: true,
  //   component: lazy(
  //     () =>
  //       import('./pages/enterprises/enterprises/Services/AtxServiceIndicators')
  //   ),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'certifications_user',
  //     'asset_management_user',
  //   ],
  // },
  // {
  //   path: '/service-value/:id/atx/designer',
  //   exact: true,
  //   component: lazy(
  //     () =>
  //       import('./pages/enterprises/enterprises/Services/Designer/ATXService')
  //   ),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'certifications_user',
  //     'asset_management_user',
  //   ],
  // },
  // {
  //   path: '/service-value/:id/atx/client',
  //   exact: true,
  //   component: lazy(
  //     () =>
  //       import('./pages/enterprises/enterprises/Services/Customer/ATXService')
  //   ),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'certifications_user',
  //     'asset_management_user',
  //   ],
  // },
  // {
  //   path: '/meters',
  //   exact: true,
  //   component: lazy(() => import('./pages/enterprises/meters')),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'asset_management_user',
  //     'certifications_user',
  //   ],
  // },
  // {
  //   path: '/forms-of-service',
  //   exact: true,
  //   component: lazy(() => import('./pages/services/forms-of-service')),
  //   roles: ['asset_management_admin', 'certifications_admin'],
  // },

  //AGIS and DOC ROUTES
  // {
  //   path: '/service-value/:id/doc/consultant',
  //   exact: true,
  //   component: lazy(
  //     () =>
  //       import('./pages/enterprises/enterprises/Services/Consultant/DOCService')
  //   ),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'certifications_user',
  //     'asset_management_user',
  //   ],
  // },

  // {
  //   path: '/service-value/:id/agis/consultant',
  //   exact: true,
  //   component: lazy(
  //     () =>
  //       import(
  //         './pages/enterprises/enterprises/Services/Consultant/AGISService'
  //       )
  //   ),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'certifications_user',
  //     'asset_management_user',
  //   ],
  // },

  // {
  //   path: '/service-value/:id/doc/consultant_jr',
  //   exact: true,
  //   component: lazy(
  //     () =>
  //       import(
  //         './pages/enterprises/enterprises/Services/ConsultantJunior/DOCService'
  //       )
  //   ),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'certifications_user',
  //     'asset_management_user',
  //   ],
  // },

  // {
  //   path: '/service-value/:id/agis/consultant_jr',
  //   exact: true,
  //   component: lazy(
  //     () =>
  //       import(
  //         './pages/enterprises/enterprises/Services/ConsultantJunior/AGISService'
  //       )
  //   ),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'certifications_user',
  //     'asset_management_user',
  //   ],
  // },
  // {
  //   path: '/service-value/:id/agis/designer',
  //   exact: true,
  //   component: lazy(
  //     () =>
  //       import('./pages/enterprises/enterprises/Services/Designer/AGISService')
  //   ),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'certifications_user',
  //     'asset_management_user',
  //   ],
  // },

  // {
  //   path: '/service-value/:id/doc/designer',
  //   exact: true,
  //   component: lazy(
  //     () =>
  //       import('./pages/enterprises/enterprises/Services/Designer/DOCService')
  //   ),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'certifications_user',
  //     'asset_management_user',
  //   ],
  // },

  // {
  //   path: '/service-value/:id/agis/client',
  //   exact: true,
  //   component: lazy(
  //     () =>
  //       import('./pages/enterprises/enterprises/Services/Customer/AGISService')
  //   ),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'certifications_user',
  //     'asset_management_user',
  //   ],
  // },
  // {
  //   path: '/service-value/:id/doc/client',
  //   exact: true,
  //   component: lazy(
  //     () =>
  //       import('./pages/enterprises/enterprises/Services/Customer/DOCService')
  //   ),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'certifications_user',
  //     'asset_management_user',
  //   ],
  // },
  // {
  //   path: '/service-value/:id/agis/indicators',
  //   exact: true,
  //   component: lazy(
  //     () =>
  //       import('./pages/enterprises/enterprises/Services/AgisServiceIndicators')
  //   ),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'certifications_user',
  //     'asset_management_user',
  //   ],
  // },

  // {
  //   path: '/service-value/:id/agis/indicators/client',
  //   exact: true,
  //   component: lazy(
  //     () =>
  //       import(
  //         './pages/enterprises/enterprises/Services/AgisServiceIndicatorsCustomer'
  //       )
  //   ),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'certifications_user',
  //     'asset_management_user',
  //   ],
  // },

  // {
  //   path: '/service-value/:id/doc/indicators',
  //   exact: true,
  //   component: lazy(
  //     () =>
  //       import('./pages/enterprises/enterprises/Services/DocServiceIndicators')
  //   ),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'certifications_user',
  //     'asset_management_user',
  //   ],
  // },
  // {
  //   path: '/service-value/:id/doc/indicators/client',
  //   exact: true,
  //   component: lazy(
  //     () =>
  //       import(
  //         './pages/enterprises/enterprises/Services/DocServiceIndicatorsCustomer'
  //       )
  //   ),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'certifications_user',
  //     'asset_management_user',
  //   ],
  // },
  //
  // ATX USULES ROUTES
  // {
  //   path: '/service-value/:id/atx/indicators/client',
  //   exact: true,
  //   component: lazy(
  //     () =>
  //       import(
  //         './pages/enterprises/enterprises/Services/AtxServiceIndicatorsCustomer'
  //       )
  //   ),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'certifications_user',
  //     'asset_management_user',
  //   ],
  // },
  // {
  //   path: '/service-value/:id/atx/pdf',
  //   exact: true,
  //   component: lazy(
  //     () =>
  //       import(
  //         './pages/enterprises/enterprises/Services/AtxServiceIndicators/ServiceIndicatorsPDF'
  //       )
  //   ),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'certifications_user',
  //     'asset_management_user',
  //   ],
  // },
  // {
  //   path: '/service-value/:id/atx/pdf/status',
  //   exact: true,
  //   component: lazy(
  //     () =>
  //       import(
  //         './pages/enterprises/enterprises/Services/AtxServiceIndicators/ServiceIndicatorsPDFStatus'
  //       )
  //   ),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'certifications_user',
  //     'asset_management_user',
  //   ],
  // },
  // {
  //   path: '/service-value/:id/atx/pdf/interventions',
  //   exact: true,
  //   component: lazy(
  //     () =>
  //       import(
  //         './pages/enterprises/enterprises/Services/AtxServiceIndicators/ServiceIndicatorsPDFInterventions'
  //       )
  //   ),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'certifications_user',
  //     'asset_management_user',
  //   ],
  // },
  // {
  //   path: '/service-value/:id/atx/pdf/diagnostic',
  //   exact: true,
  //   component: lazy(
  //     () =>
  //       import(
  //         './pages/enterprises/enterprises/Services/AtxServiceIndicators/ServiceIndicatorsPDFDiagnostic'
  //       )
  //   ),
  //   roles: [
  //     'asset_management_admin',
  //     'certifications_admin',
  //     'certifications_user',
  //     'asset_management_user',
  //   ],
  // },
];

const Router: FunctionComponent<{}> = () => {
  return (
    <IndexLayout>
      <Suspense fallback={<Loader />}>
        <Switch>
          {routes.map((route) => (
            <Route
              path={route.path}
              component={route.component}
              key={route.path}
              exact={route.exact}
              roles={route.roles}
            />
          ))}
          <Route component={NotFoundPage} />
        </Switch>
      </Suspense>
    </IndexLayout>
  );
};

export default Router;
