import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import DebounceLink from 'apollo-link-debounce';
import { Notification } from '../services/notification';

const authLink = setContext((_, { headers }) => {
  const token = sessionStorage.getItem('@cte:token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );

      Notification.error('Erro', message);
    });

  if (networkError) {
    // AlertProvider.error('Erro', networkError.message)
    console.error(`[Network error]: ${networkError}`);
    Notification.error('Erro', networkError.message);
  }
});

const debounceLink = new DebounceLink(200);

const httpLink = createUploadLink({
  uri:
    process.env.NODE_ENV === 'development'
      ? `${process.env.REACT_APP_LOCAL_URL}/graphql`
      : `${process.env.REACT_APP_API_URL}/graphql`
});

const apiAddress =
  process.env.NODE_ENV === 'development'
  ? `${process.env.REACT_APP_LOCAL_URL}/graphql`
  : `${process.env.REACT_APP_API_URL}/graphql`;

const apiAddressToFiles =
  process.env.NODE_ENV === 'development'
    ? `${process.env.REACT_APP_LOCAL_URL}`
    : `${process.env.REACT_APP_API_URL}`;

const client = new ApolloClient({
  link: authLink.concat(errorLink).concat(httpLink).concat(debounceLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export { client, apiAddress, apiAddressToFiles };
