/*eslint no-unused-expressions: off*/
import React from 'react';
/*eslint no-unused-expressions: off*/
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import AntdConfigProvider from './components/AntdConfigProvider';
import Router from './router';
import ContextsProvider from './contexts/Provider';
import { client } from './config/graphql';
import { BrowserRouter } from 'react-router-dom';

// app styles
import './global.scss';

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <ContextsProvider>
        <AntdConfigProvider>
          <Router />
        </AntdConfigProvider>
      </ContextsProvider>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById('root')
);

// serviceWorker.register()
