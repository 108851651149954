const indicatorsLabel = {
  measurement_id: {
    pt: 'ID da medição',
    en: 'Measurement ID',
    es: 'ID da medida',
  },
  installation_code: {
    pt: 'Código de instalação',
    en: 'Installation code',
    es: 'Codigo de instalacion',
  },
  unique_identifier: {
    pt: 'Identificador Único',
    en: 'Project ID',
    es: 'Identificador Único',
  },
  energy_indicators: {
    pt: 'Indicadores de energia',
    en: 'Energy indicators',
    es: 'Indicadores energéticos',
  },
  performance_indicators: {
    pt: 'Indicadores de performance',
    en: 'Performance Indicators',
    es: 'Indicadores de desempeño',
  },
  carbon_indicators: {
    pt: 'Indicadores de carbono',
    en: 'Carbon indicators',
    es: 'Indicadores de carbón',
  },
  water_indicators: {
    pt: 'Indicadores de água',
    en: 'Water indicators',
    es: 'Indicadores de agua',
  },
  potable_water: {
    pt: 'Água potável',
    en: 'Potable water',
    es: 'Agua potable',
  },
  rainwater: {
    pt: 'Água de chuva',
    en: 'Rainwater',
    es: 'Agua de lluvia',
  },
  non_potable_water: {
    pt: 'Água não potável',
    en: 'Non potable water',
    es: 'Agua no potable',
  },
  percentage_of_renewable_energy: {
    pt: 'Energia renovável',
    en: 'Renewable energy',
    es: 'Energía renovable',
  },
  electrical_energy: {
    pt: 'Energia elétrica',
    en: 'Electrical energy',
    es: 'Energia electrica',
  },
  consumption_units: {
    pt: 'Unidades de consumo',
    en: 'Consumption units',
    es: 'Unidades de consumo',
  },
  area: {
    pt: 'Área do projeto (m²)',
    en: 'Project area (m²)',
    es: 'Área del proyecto (m²)',
  },
  base_year: {
    pt: 'Ano base',
    en: 'Base year',
    es: 'Año base',
  },
  measurement_unit: {
    pt: 'Unidade de medição',
    en: 'Measurement unit',
    es: 'Unidad de medida',
  },
  prerequisitesCertificate: {
    pt: 'Pré-requisitos da certificação',
    en: 'Mandatory Certifications',
    es: 'Certificaciones obligatorias',
  },
  mandatoryItens: {
    pt: 'Itens mandatórios',
    en: 'Mandatory Items',
    es: 'Ítems obligatorios',
  },
  certification_potential: {
    pt: 'Potencial de certificação',
    en: 'Certification potential',
    es: 'Potencial de certificación',
  },
  construction_waste: {
    pt: 'Resíduos de obra',
    en: 'Construction waste',
    es: 'Residuos de la construcción',
  },
  target_conversion: {
    pt: 'Conversão do alvo',
    en: 'Target conversion',
    es: 'Conversión objetivo',
  },
  reduced_by: {
    pt: 'Reduzido por',
    en: 'Reduced by',
    es: 'Reducido por',
  },
  increase_by: {
    pt: 'Aumento por',
    en: 'Increase by',
    es: 'Aumentado por',
  },
  target_year: {
    pt: 'Ano do alvo',
    en: 'Target year',
    es: 'Año objetivo',
  },
};

export default indicatorsLabel;
