const confirmationMessages = {
  //CREATE CONFIRMATION MESSAGES
  create_enterprise_tree: {
    pt: 'Deseja criar a árvore de empreendimento?',
    en: 'Do you want to create a tree of enterprise?',
    es: '¿Desea crear un arból del empreedimiento?',
  },
  saveQuestion: {
    pt: 'Salvar as mudanças antes de sair?',
    en: 'Save changes before exiting?',
    es: 'Guardar los cambios antes de salir?',
  },

  // EDIT CONFIRMATION MESSAGES
  confirm_edit: {
    pt: 'Você realmente deseja editar?',
    en: 'Are you sure that you want to edit?',
    es: '¿De verdad quieres editar?',
  },

  //DELETE CONFIRMATION MESSAGES
  confirm_delete_measure: {
    pt: 'Deseja realmente deletar esse tipo de medição?',
    en: 'Are you sure that you want to delete this measurer type?',
    es: '¿Desea eliminar efectivamente este tipo de medición?',
  },
  confirm_delete: {
    pt: 'Deseja realmente remover esse campo?',
    en: 'Are you sure that you want to delete this field?',
    es: '¿Desea eliminar efectivamente este campo?',
  },
  confirm_delete_column: {
    pt: 'Deseja realmente deletar essa coluna?',
    en: 'Are you sure that you want to delete this column?',
    es: '¿Desea eliminar efectivamente esta columna?',
  },
  confirm_delete_form_of_service: {
    pt: 'Deseja realmente deletar essa forma de atendimento?',
    en: 'Are you sure that you want to delete this type of attention?',
    es: '¿Desea eliminar efectivamente esta forma de atención?',
  },
  confirm_delete_object: {
    pt: 'Deseja realmente remover esse tipo de objeto?',
    en: 'Are you sure that you want to delete this type of object?',
    es: '¿Desea eliminar efectivamente este tipo de objeto',
  },
  confirm_delete_report: {
    pt: 'Deseja realmente deletar esse relatório?',
    en: 'Are you sure that you want to delete this report?',
    es: '¿Desea eliminar efectivamente este informe?',
  },
  confirm_delete_status: {
    pt: 'Deseja realmente remover esse status?',
    en: 'Are you sure that you want to delete this status?',
    es: '¿Desea eliminar efectivamente este status?',
  },
  confirm_delete_project: {
    pt: 'Deseja realmente deletar esse projeto?',
    en: 'Are you sure that you want to delete this project?',
    es: '¿Desea eliminar efectivamente este proyecto?',
  },
  confirm_delete_records: {
    pt: 'Deseja realmente deletar esse registro?',
    en: 'Are you sure that you want to delete this register?',
    es: '¿Desea eliminar efectivamente este registro?',
  },
  confirm_delete_service: {
    pt: 'Deseja realmente remover esse serviço?',
    en: 'Are you sure that you want to delete this serviço?',
    es: '¿Desea eliminar efectivamente este servicio?',
  },
  confirm_enable_auto_save: {
    pt: 'Deseja cancelar salvamento automático?',
    en: 'Do you want to cancel autosave?',
    es: '¿Quieres cancelar el guardado automático?',
  },
  confirm_delete_enterprise: {
    pt: 'Tem certeza que deseja deletar este empreendimento?',
    en: 'Are you sure that you want to delete this project?',
    es: '¿Estás seguro de que deseas eliminar este proyecto?',
  },
  confirm_remove_link: {
    pt: 'Tem certeza que deseja remover o vínculo desse empreendimento?',
    en: 'Do you really want to remove the link of this enterprise?',
    es: '¿Está seguro de que desea eliminar el enlace de esta empresa?',
  },
  confirm_delete_this: {
    pt: 'Deseja realmente deletar isso?',
    en: 'Are you sure that you want to delete?',
    es: '¿Desea eliminar efectivamente esto?',
  },
  confirm_delete_values: {
    pt: 'Tem certeza que deseja remover essa coluna?',
    en: 'Are you sure that you want to delete this column?',
    es: '¿Desea eliminar esta columna?',
  },
  confirm_delete_company: {
    pt: 'Deseja realmente remover essa empresa?',
    en: 'Are you sure that you want to delete this company?',
    es: '¿Desea eliminar efectivamente esta compañía?',
  },
  confirm_delete_user: {
    pt: 'Deseja realmente remover esse usuário?',
    en: 'Are you sure that you want to delete this user?',
    es: '¿Desea eliminar efectivamente este usuario?',
  },
  confirm_delete_designer: {
    pt: 'Deseja realmente remover esse projetista?',
    en: 'Are you sure that you want to delete this designer?',
    es: '¿Desea eliminar efectivamente este diseñador?',
  },

  delete_confirmation: {
    pt: 'Você realmente deseja deletar?',
    en: 'Are you sure that you want to delete?',
    es: '¿Desea eliminar efectivamente?',
  },
  remove_confirm: {
    pt: 'Confirmar remoção?',
    en: 'Confirm delete?',
    es: 'Confirmar eliminación?',
  },

  //OTHER CONFIRMATION MESSAGES
  main_report: {
    pt: 'Relatório principal?',
    en: 'Main report?',
    es: 'Informe principal?',
  },

  is_required: {
    pt: 'É obrigatório?',
    en: 'Is it obligatory?',
    es: '¿Es obligatorio?',
  },
  has_specifications: {
    pt: 'Possui especificações?',
    en: 'Has specifications?',
    es: '¿Tienes especificaciones?',
  },
  requirement_is_basis: {
    pt: 'Requisito é base?',
    en: 'Is requirement basis?',
    es: '¿El requisito es básico?',
  },
  is_evolutionary_punctuation: {
    pt: 'Pontuação evolutiva?',
    en: 'Is evolutionary score?',
    es: '¿Puntuación evolutiva?',
  },
  has_service_group: {
    pt: 'Há mais um grupo de pontuação evolutiva?',
    en: 'There is one more evolutionary scoring group?',
    es: '¿Hay un grupo de puntuación evolutivo más?',
  },
  is_the_installation_code_mandatory: {
    pt: 'Código da instalação obrigatório?',
    en: 'Is the installation code required?',
    es: '¿Código de instalación obligatorio?',
  },
};

export default confirmationMessages;
