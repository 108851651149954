import React, { useContext, useState } from 'react';
import { Dropdown, Menu, Row, Col } from 'antd';
import { UserContext } from 'contexts/UserContext';
import styles from './style.module.scss';
import { languagesArray } from '../../../../constants/languages';
import { useMutation } from 'react-apollo';
import { ChangeLanguageResponse, ChangeLanguageVariables } from './interface';
import { CHANGE_LANGUAGE } from './graphql';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const LanguageSelector = () => {
  const { user, setLanguage, language, setUser } = useContext(UserContext);
  const [status, setStatus] = useState<boolean | null>(null);

  const [changeLanguage] = useMutation<
    ChangeLanguageResponse,
    ChangeLanguageVariables
  >(CHANGE_LANGUAGE);

  const handleChangeLanguage = async (e): Promise<void> => {
    const {
      data: { changeLanguage: changeLanguageResponse },
    } = await changeLanguage({
      variables: {
        id: user.id,
        language: e.key,
      },
    });

    if (changeLanguageResponse) {
      setStatus(true);
      setUser({
        ...user,
        language: e.key,
      });
      sessionStorage.setItem(
        '@cte:user',
        JSON.stringify({
          ...user,
          language: e.key,
        })
      );
      setLanguage(e.key);
    } else {
      setStatus(false);
    }

    setTimeout(() => {
      setStatus(null);
    }, 1500);
  };

  const getStatus = () => {
    if (status === false) {
      return <CloseOutlined className={styles.error} />;
    } else if (status === null) {
      return '';
    } else {
      return <CheckOutlined className={styles.success} />;
    }
  };

  const langMenu = (
    <Menu className={styles.menu} onClick={handleChangeLanguage}>
      {languagesArray.map((itemLanguage) => (
        <Menu.Item key={itemLanguage.initial}>
          <span
            role="img"
            aria-label={itemLanguage.name[language]}
            className="text-uppercase mr-2"
          >
            {itemLanguage.initial}
          </span>
          {itemLanguage.name[language]}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <>
      <Row gutter={12} align="middle">
        <Col>{getStatus()}</Col>
        <Col>
          <Dropdown overlay={langMenu} trigger={['click']}>
            <div className={styles.dropdown}>
              <strong className="text-uppercase">{language}</strong>
            </div>
          </Dropdown>
        </Col>
      </Row>
    </>
  );
};

export default LanguageSelector;
