const responsibleFilter = {
  '1': { pt: 'Arquitetura', en: 'Architecture', es: 'Arquitectura' },
  '17': {
    pt: 'Ar-Condicionado',
    en: 'Air conditioner',
    es: 'Aire condicionado',
  },
  '18': { pt: 'Acústica', en: 'Acoustics', es: 'Acústica' },
  '19': { pt: 'Automação', en: 'Automation', es: 'Automatización' },
  '20': { pt: 'Cliente', en: 'Client', es: 'Cliente' },
  '21': {
    pt: 'Comunicação Visual',
    en: 'Visual Communication',
    es: 'Comunicación Visual',
  },
  '22': { pt: 'Construtora', en: 'Construction', es: 'Constructora' },
  '23': { pt: 'Cozinha', en: 'Kitchen', es: 'Cocina' },
  '24': { pt: 'Drenagem', en: 'Drainage', es: 'Drenaje' },
  '25': { pt: 'Elétrica', en: 'Electricity', es: 'Eléctrica' },
  '26': { pt: 'Hidráulica', en: 'Hydraulics', es: 'Hidráulica' },
  '27': { pt: 'Irrigação', en: 'Irrigation', es: 'Riego' },
  '28': { pt: 'Luminotécnica', en: 'Lighting', es: 'Luminotecnia' },
  '29': { pt: 'Paisagismo', en: 'Landscaping', es: 'Paisajismo' },
  '30': { pt: 'Acessibilidade', en: 'Accessibility', es: 'Accesibilidad' },
  '31': { pt: 'Instalações', en: 'Facilities', es: 'Comodidades' },
  '32': { pt: 'RH', en: 'HR', es: 'RRHH' },
  '33': { pt: 'Comissionamento', en: 'Commissioning', es: 'Comisionamiento' },
  '34': { pt: 'CTE', en: 'CTE', es: 'CTE' },
};

export default responsibleFilter;
