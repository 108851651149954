/*eslint no-unused-expressions: off*/
import React, { FunctionComponent } from 'react';
import { withRouter, Redirect, RouteComponentProps } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PublicLayout from './Public';
import LoginLayout from './Login';
import MainLayout from './Main';

const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  main: MainLayout,
};

const IndexLayout: FunctionComponent<RouteComponentProps> = ({
  location: { pathname },
  children,
}) => {
  const user = sessionStorage.getItem('@cte:user');

  const getLayout = () => {
    if (
      pathname === '/login_contratos' ||
      pathname === '/login_ativos' ||
      pathname === '/login_certificacoes' ||
      pathname.includes('/set-password') ||
      pathname === '/password-success' ||
      pathname === '/recover-success' ||
      pathname === '/forgot-password' ||
      pathname === '/register'
    ) {
      return 'login';
    }
    return 'main';
  };

  const Container = Layouts[getLayout()];
  const isLoginLayout = getLayout() === 'login';

  const BootstrappedLayout = () => {
    // redirect to login page if current is not login page and user not authorized
    if (!isLoginLayout && !user) {
      return <Redirect to="/login_certificacoes" />;
    }
    // redirect to main dashboard when user on login page and authorized
    if (isLoginLayout && !!user) {
      return <Redirect to="/" />;
    }
    // in other case render previously set layout
    return <Container>{children}</Container>;
  };

  return (
    <>
      <Helmet titleTemplate="CTE | %s" title="Início" />
      {BootstrappedLayout()}
    </>
  );
};

export default withRouter(IndexLayout);
