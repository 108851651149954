import React, { FunctionComponent } from 'react';
import { UserProvider } from './UserContext';
import { ProjectProvider } from '../pages/projects/context';
import { LayoutProvider } from './LayoutContext';
import { TopMenuProvider } from './TopMenuContext';
import { IndicatorsProvider } from './IndicatorsContext';

const Provider: FunctionComponent<{}> = ({ children }) => {
  return (
    <TopMenuProvider>
      <UserProvider>
        <ProjectProvider>
          <IndicatorsProvider>
            <LayoutProvider>{children}</LayoutProvider>
          </IndicatorsProvider>
        </ProjectProvider>
      </UserProvider>
    </TopMenuProvider>
  );
};

export default Provider;
