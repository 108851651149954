/*eslint no-unused-expressions: off*/
import React from 'react';
import {
  LineChartOutlined,
  HomeOutlined,
  LayoutOutlined,
  UserOutlined,
  FundOutlined,
  ForkOutlined,
} from '@ant-design/icons';

export const getUserMenu = (): any => {
  return [
    {
      title: {
        pt: 'Projetos',
        en: 'Projects',
        es: 'Proyectos',
      },
      key: 'projects',
      icon: <FundOutlined />,
      url: '/projects',
      roles: [
        'asset_management_admin',
        'certifications_admin',
        'certifications_user',
        'certifications_internal_user',
        'certifications_external_user',
        'consultant_jr',
      ],
    },
    {
      title: {
        pt: 'Perfil',
        en: 'Profile',
        es: 'Pefil',
      },
      key: 'profile',
      icon: <UserOutlined />,
      url: '/profile',
      roles: ['certifications_user', 'certifications_external_user'],
    },
    {
      title: {
        pt: 'Projetos',
        en: 'Projects',
        es: 'Proyectos',
      },
      key: 'enterprises',
      icon: <HomeOutlined />,
      roles: ['certifications_admin', , 'certifications_internal_user'],
      url: '/enterprises',
    },
    {
      title: {
        pt: 'Dashboard',
        en: 'Dashboard',
        es: 'Dashboard',
      },
      key: 'dashboard',
      icon: <LineChartOutlined />,
      url: '/',
      roles: [
        'asset_management_admin',
        'certifications_admin',
        'asset_management_user',
      ],
    },
    {
      title: {
        pt: 'Empreendimentos',
        en: 'Enterprises',
        es: 'Emprendimientos',
      },
      key: 'enterprises',
      icon: <HomeOutlined />,
      roles: ['asset_management_admin', 'asset_management_user'],
      children: [
        {
          title: {
            pt: 'Empreendimentos',
            en: 'Enterprises',
            es: 'Emprendimientos',
          },
          key: 'enterprises',
          url: '/enterprises',
          roles: ['asset_management_admin', 'asset_management_user'],
        },
        {
          title: {
            pt: 'Medidores',
            en: 'Measurers',
            es: 'Medidores',
          },
          key: 'measurer',
          roles: ['asset_management_admin'],
          children: [
            {
              title: {
                pt: 'Tipo de medidores',
                en: 'Measurer types',
                es: 'Tipo de medidores',
              },
              key: 'measurer-types',
              url: '/meters',
              roles: ['asset_management_admin'],
            },
            {
              title: {
                pt: 'Colunas personalizáveis',
                en: 'Customizable columns',
                es: 'Columnas personalizables',
              },
              key: 'meters-customizable-columns',
              url: '/meters/customizable-columns',
              roles: ['asset_management_admin'],
            },
          ],
        },
      ],
    },

    {
      title: {
        pt: 'Relatórios',
        en: 'Reports',
        es: 'Informes',
      },
      key: 'reports',
      url: '/reports',
      icon: <FundOutlined />,
      roles: ['asset_management_admin'],
    },
  ];
};

export const getAdminMenu = (): any => {
  return [
    {
      title: {
        pt: 'Usuários',
        en: 'Users',
        es: 'Usuarios',
      },
      key: 'users',
      icon: <UserOutlined />,
      roles: [
        'asset_management_admin',
        'certifications_admin',
        'certifications_internal_user',
        'consultant_jr',
      ],
      children: [
        {
          title: {
            pt: 'Usuários',
            en: 'Users',
            es: 'Usuarios',
          },
          key: 'users',
          url: '/users',
        },
        {
          title: {
            pt: 'Projetistas',
            en: 'Designers',
            es: 'Diseñadores',
          },
          key: 'designers',
          url: '/users/designers',
          roles: ['asset_management_admin', 'certifications_admin'],
        },
        {
          title: {
            pt: 'Empresas',
            en: 'Companies',
            es: 'Compañías',
          },
          key: 'companies',
          url: '/enterprises/companies',
          roles: [
            'asset_management_admin',
            'certifications_admin',
            'certifications_internal_user',
            'consultant_jr',
          ],
        },
      ],
    },
    {
      title: {
        pt: 'Hierarquia de objetos',
        en: 'Object hierarchy',
        es: 'Jerarquía de objetos',
      },
      key: 'objects-hierarchies',
      icon: <ForkOutlined />,
      roles: ['asset_management_admin', 'certifications_admin'],
      children: [
        {
          title: {
            pt: 'Indicadores',
            en: 'Indicators',
            es: 'Indicadores',
          },
          key: 'indicators',
          url: '/object-hierarchies/indicators',
        },
        {
          title: {
            pt: 'Tipos de objeto',
            en: 'Type of objects',
            es: 'Tipos de objeto',
          },
          key: 'object-types',
          url: '/object-hierarchies/object-types',
        },
        {
          title: {
            pt: 'Campos P. de Hierarquia',
            en: 'Customizable fields',
            es: 'Campos personalizables',
          },
          key: 'customizable-fields',
          url: '/object-hierarchies/customizable-fields',
        },
      ],
    },
    {
      title: {
        pt: 'Projetos',
        en: 'Projects',
        es: 'Proyectos',
      },
      key: 'enterprises',
      icon: <HomeOutlined />,
      roles: [
        'asset_management_admin',
        'certifications_admin',
        'asset_management_user',
        'certifications_internal_user',
      ],
      children: [
        {
          title: {
            pt: 'Projetos',
            en: 'Projects',
            es: 'Proyectos',
          },
          key: 'enterprises',
          url: '/enterprises',
          roles: [
            'asset_management_admin',
            'certifications_admin',
            'asset_management_user',
            'certifications_internal_user',
          ],
        },
        {
          title: {
            pt: 'Medidores',
            en: 'Measurers',
            es: 'Medidores',
          },
          key: 'measurer',
          roles: ['asset_management_admin'],
          children: [
            {
              title: {
                pt: 'Tipo de medidores',
                en: 'Measurer types',
                es: 'Tipo de medidores',
              },
              key: 'measurer-types',
              url: '/meters',
              roles: ['asset_management_admin'],
            },
            {
              title: {
                pt: 'Colunas personalizáveis',
                en: 'Customizable columns',
                es: 'Columnas personalizables',
              },
              key: 'meters-customizable-columns',
              url: '/meters/customizable-columns',
              roles: ['asset_management_admin'],
            },
          ],
        },
      ],
    },
    {
      title: {
        pt: 'Serviços',
        en: 'Services',
        es: 'Servicios',
      },
      key: 'services',
      icon: <LayoutOutlined />,
      roles: ['asset_management_admin', 'certifications_admin'],
      children: [
        {
          title: {
            pt: 'Serviços',
            en: 'Services',
            es: 'Servicios',
          },
          key: 'services/services',
          url: '/services',
          roles: ['asset_management_admin', 'certifications_admin'],
        },
        {
          title: {
            pt: 'Status',
            en: 'Status',
            es: 'Status',
          },
          key: 'status',
          url: '/status',
          roles: ['asset_management_admin', 'certifications_admin'],
        },
        {
          title: {
            pt: 'Campos P. de Serviço',
            en: 'Customizable field',
            es: 'Campos personalizables',
          },
          key: 'services/customizable-fields',
          url: '/customizable-fields',
          roles: ['asset_management_admin', 'certifications_admin'],
        },
      ],
    },
  ];
};
