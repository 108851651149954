const languagesArray = [
  {
    initial: 'pt',
    name: {
      pt: 'Português',
      en: 'Portuguese',
      es: 'Portugués',
    },
  },
  {
    initial: 'en',
    name: {
      pt: 'Inglês',
      en: 'English',
      es: 'Inglés',
    },
  },
  {
    initial: 'es',
    name: {
      pt: 'Espanhol',
      en: 'Spanish',
      es: 'Español',
    },
  },
];

const languagesObject = {
  pt: {
    pt: 'Português',
    en: 'Portuguese',
    es: 'Portugués',
  },
  en: {
    pt: 'Inglês',
    en: 'English',
    es: 'Inglés',
  },
  es: {
    pt: 'Espanhol',
    en: 'Spanish',
    es: 'Español',
  },
};

export { languagesArray, languagesObject };
