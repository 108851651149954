/*eslint no-unused-expressions: off*/
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { CustomerServiceOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { TopMenuContext } from 'contexts/TopMenuContext';
import { UserContext } from 'contexts/UserContext';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  Link,
  RouteComponentProps,
  useHistory,
  withRouter,
} from 'react-router-dom';
import { getAdminMenu, getUserMenu } from '../../../../config/menu';
import { LayoutContext } from '../../../../contexts/LayoutContext';
import ContactModal from '../ContactModal';
import pageLanguage from 'language';
import './resetAntdStyles.scss';
import styles from './style.module.scss';
const { Sider } = Layout;
const { SubMenu, Divider } = Menu;

const MenuLeft: FunctionComponent<RouteComponentProps> = ({
  location: { pathname },
}) => {
  const { language, user, setMenuModal } = useContext(UserContext);
  const { isMobileView } = useContext(LayoutContext);
  const { activeTopMenu } = useContext(TopMenuContext);

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [openedKeys, setOpenedKeys] = useState<string[]>([]);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [menu, setMenu] = useState([]);

  const history = useHistory();

  const menuImage = () => {
    if (!isMobileView && !isCollapsed) {
      return '/resources/images/plus-by-cte-logo-cond.svg';
    }
    if (
      (user.role === 'certifications_user' ||
        user.role === 'certifications_admin' ||
        user?.role === 'consultant_jr' ||
        user.role === 'certifications_internal_user') &&
      !isCollapsed
    ) {
      return '/resources/images/plus-by-cte-logo.svg';
    }
    if (!isMobileView && isCollapsed) {
      return '/resources/images/plus-by-cte-logo-cond.svg';
    }

    return '/resources/images/logo-short-inverse.png';
  };

  const setMenuSelectedKeys = () => {
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item);
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key));
        }
        return flattenedItems;
      }, []);

    const selectedItem = _.find(flattenItems(menu, 'children'), [
      'url',
      pathname,
    ]);

    setSelectedKeys(selectedItem ? [selectedItem[0]] : []);
  };

  const onCollapse = (value, type) => {
    if (type === 'responsive' && isCollapsed) {
      return;
    }

    setIsCollapsed(!isCollapsed);
  };

  const handleClick = (event) => {
    if (event.key === 'contact-us') {
      setMenuModal(true);
    }
    setSelectedKeys([event.key]);
  };

  const onOpenChange = (keys) => {
    setOpenedKeys(keys);
  };

  const generateMenuItems = () => {
    const userData = JSON.parse(sessionStorage.getItem('@cte:user'));

    const generateItem = (item) => {
      const { key, title, url, icon: Icon, disabled, pro } = item;
      if (item.divider) {
        return <Divider key={`${title}-${key}`} />;
      }
      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled}>
            {item.target ? (
              <a href={url} target={item.target} rel="noopener noreferrer">
                {Icon}
                <span className={styles.title}>{title[language]}</span>
                {pro && (
                  <span className="badge badge-primary badge-collapsed-hidden ml-2">
                    PRO
                  </span>
                )}
              </a>
            ) : (
              <Link to={url}>
                {Icon}
                <span className={styles.title}>{title[language]}</span>
                {pro && (
                  <span className="badge badge-primary badge-collapsed-hidden ml-2">
                    PRO
                  </span>
                )}
              </Link>
            )}
          </Menu.Item>
        );
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          {Icon}
          <span className={styles.title}>{title[language]}</span>
          {pro && (
            <span className="badge badge-primary badge-collapsed-hidden ml-2">
              PRO
            </span>
          )}
        </Menu.Item>
      );
    };

    const generateSubmenu = (items) =>
      items
        .filter(
          (menuItem) =>
            !menuItem.roles || menuItem.roles.includes(userData.role)
        )
        .map((menuItem, index) => {
          if (menuItem.children) {
            const subMenuTitle = (
              <span key={index}>
                <span className={styles.title}>{menuItem.title[language]}</span>
                <span className={styles.icon}>{menuItem.icon}</span>
              </span>
            );
            return (
              <SubMenu title={subMenuTitle} key={index}>
                {generateSubmenu(menuItem.children)}
              </SubMenu>
            );
          }
          return generateItem(menuItem);
        });

    return menu
      .filter(
        (menuItem) => !menuItem.roles || menuItem.roles.includes(userData.role)
      )
      .map((menuItem) => {
        if (menuItem.children) {
          const subMenuTitle = menuItem.icon;
          return (
            <SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </SubMenu>
          );
        }
        return generateItem(menuItem);
      });
  };

  useEffect(() => {
    if (activeTopMenu === 'admin') {
      setMenu(getAdminMenu());
    } else {
      if (user?.role === 'certifications_user') setIsCollapsed(true);
      setMenu(getUserMenu());
    }

    setMenuSelectedKeys();
  }, [activeTopMenu, user]);

  const getScreenType = () => {
    if (!isMobileView && isCollapsed) {
      return '-mobile';
    } else {
      return '-short-inverse';
    }
  };

  return (
    <>
      <Sider
        width={256}
        collapsed={!isMobileView}
        onCollapse={onCollapse}
        breakpoint="lg"
        className={`${styles.menu} ${styles.light}`}
      >
        <div className={styles.logo}>
          <div
            className={styles.logoContainer}
            onClick={() => history.push('/projects')}
          >
            {user?.role === 'certifications_user' ||
            user?.role === 'certifications_admin' ||
            user?.role === 'consultant_jr' ||
            user?.role === 'certifications_internal_user' ? (
              <img src={menuImage()} alt="cte logo" />
            ) : (
              <img
                src={`/resources/images/logo${getScreenType()}.png`}
                alt=""
              />
            )}
          </div>
        </div>
        <Scrollbars
          className={styles.scrollbarDesktop}
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                width: '4px',
                borderRadius: 'inherit',
                backgroundColor: '#c5cdd2',
                left: '1px',
              }}
            />
          )}
          autoHide
        >
          <Menu
            theme="light"
            onClick={handleClick}
            selectedKeys={selectedKeys}
            openKeys={openedKeys}
            onOpenChange={onOpenChange}
            mode="inline"
            className={styles.navigation}
          >
            {generateMenuItems()}
            <Menu.ItemGroup>
              <Menu.Item key="contact-us">
                <CustomerServiceOutlined />
                <span>{pageLanguage.suport[language]}</span>
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        </Scrollbars>
      </Sider>
      <ContactModal
        description={pageLanguage.description_suport[language]}
        title={pageLanguage.suport[language]}
      />
    </>
  );
};

export default withRouter(MenuLeft);
