/*eslint no-unused-expressions: off*/
import React, { FunctionComponent } from 'react'
import { Redirect, Route as RouterRoute, RouteProps } from 'react-router-dom'
import { User } from '../../interfaces/User'

const Route: FunctionComponent<{
  roles?: string[]
} & RouteProps> = ({ roles, ...props }) => {
  const userString = sessionStorage.getItem('@cte:user')

  let user = null

  if (userString) {
    user = JSON.parse(userString) as User
  }

  if (!roles || roles.includes(user.role)) {
    return <RouterRoute {...props} />
  }

  return <Redirect to="/404" />
}

export default Route
