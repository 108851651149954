const validationMessages = {
  name_error: {
    pt: 'Nome inválido',
    en: 'Invalid name',
    es: 'Nombre inválido',
  },
  please_enter_name: {
    pt: 'Por favor, insira um nome',
    en: 'Please enter a name',
    es: 'Por favor, insira un nombre',
  },
  phone_error: {
    pt: 'Telefone inválido',
    en: 'Invalid phone',
    es: 'Teléfono inválido',
  },
  invalid_area: {
    pt: 'Área do projeto inválida',
    en: 'Invalid project area',
    es: 'Área del proyecto inválida',
  },
  invalid_phone: {
    pt: 'Telefone inválido',
    en: 'Invalid phone',
    es: 'Teléfono inválido',
  },
  please_enter_phone: {
    pt: 'Por favor, insira Telefone',
    en: 'Please enter phone',
    es: 'Por favor, insira Teléfono',
  },
  not_select_service: {
    pt: 'Por favor, antes selecione a forma de atendimento.',
    en: 'Please, select the form of service first.',
    es: 'Por favor, seleccione primero la forma de atención.',
  },
  not_image_error: {
    pt: 'O arquivo inserido não é uma imagem',
    en: 'The inserted file is not an image',
    es: 'El archivo insertado no es una imagen',
  },
  invalidImageFormat: {
    pt: 'Formato de imagem inválido',
    en: 'Invalid image format',
    es: 'Formato de imagen inválido',
  },
  file_type_not_accepted: {
    pt: 'Tipo de arquivo não aceito',
    en: 'Unsupported file type',
    es: 'Tipo de archivo no admitido',
  },
  file_too_big: {
    pt: 'Arquivo maior que 100 MB',
    en: 'File larger than 100 MB',
    es: 'Archivo de más de 100 MB',
  },
  invalid_zip_code: {
    pt: 'CEP inválido',
    en: 'Invalid zip code',
    es: 'CEP inválido',
  },
  invalid_cnpj: {
    pt: 'CNPJ inválido',
    en: 'Invalid CNPJ',
    es: 'CNPJ inválido',
  },
  invalid_address_number: {
    pt: 'Número inválido',
    en: 'Invalid number',
    es: 'Número inválido',
  },
  unique_name: {
    pt: 'Este nome já está em uso',
    en: 'This name is already in use',
    es: 'Este nombre ya esta en uso',
  },
  error_validation: {
    pt: 'Já existe uma opção com este nome',
    en: 'An option with this name already exists',
    es: 'Ya existe una opción  con este nombre',
  },
  pointsMustBeGreaterThanOne: {
    pt: 'Pontuação deve ser maior que 0,001',
    en: 'Points must be greater than 0,001',
    es: 'Puntos deben ser mayores que 0,001',
  },
  required_fields: {
    pt: 'Campos obrigatórios',
    en: 'Required field',
    es: 'Campos obligatórios',
  },
  data_list_is_required: {
    pt: 'Lista de dados é obrigatório',
    en: 'Data list is required',
    es: 'Lista de datos es obligatório',
  },
  measurement_source_is_required: {
    pt: 'Fonte da medição é obrigatório',
    en: 'Measurement source is required',
    es: 'La fuente de medición es obligatoria',
  },
  warning_message: {
    pt: 'Serviços em andamento não podem ser excluídos',
    en: 'Ongoing services cannot be deleted',
    es: 'Los Servicios en curso no se pueden eliminar',
  },
  warning_user_permission_message: {
    pt:
      'Há algo errado em relação a sua função aplicada a este projeto, por favor acesse o projeto novamente',
    en:
      'There is something wrong with your role applied to this project, please access the project again',
    es:
      'Hay algún problema con su función aplicada a este proyecto, acceda de nuevo al proyecto',
  },
  files_not_selected: {
    pt: 'Arquivos não selecionados',
    en: 'Files not selected',
    es: 'Archivos no seleccionados',
  },
  repeat_new_password_incorrect: {
    pt: 'As senhas novas são diferentes',
    en: 'The new password are different',
    es: 'Las nuevas contraseñas son diferentes',
  },
  link_enterprise: {
    pt: 'Empreendimento vinculado',
    en: 'Enterprise linked',
    es: 'Emprendimiento vinculado',
  },
  column_with_values: {
    pt: 'Essa coluna contém valores salvos',
    en: 'This column already has values',
    es: 'Esta columna contiene valores guardados',
  },
  name_chapter_rule: {
    pt: 'Por favor, insira Nome do capítulo',
    en: 'Please enter Chapter Name',
    es: 'Por favor ingrese el nombre del capítulo',
  },
  please_select: {
    pt: 'Por favor, selecione',
    en: 'Please, select',
    es: 'Por favor seleccione',
  },
  select_responsible: {
    pt: 'Selecione o responsável',
    en: 'Select the responsible',
    es: 'Seleccione el responsable',
  },
  select_form_of_service: {
    pt: 'Selecione a forma de atendimento',
    en: 'Select the form of service',
    es: 'Seleccione la forma de atención',
  },
  form_of_service_list_is_required: {
    pt: 'Lista de formas de serviço é obrigatório',
    en: 'List of service forms is required',
    es: 'La lista de formularios de servicio es obligatoria',
  },
  create_tree_required: {
    pt: 'É obrigatório selecionar se deseja criar a árvore de empreendimento',
    en: "It's required to select if you want to create the enterprise tree",
    es: 'Es obligatorio seleccionar si se crea el árbol',
  },
  not_applicable_description: {
    pt: 'Não se aplica ao projeto por suas características intrínsecas.',
    en:
      'It does not apply to the project due to its intrinsic characteristics.',
    es: 'No aplica al proyecto por sus características intrínsecas.',
  },
  finalPointsMustBeGreaterThanInitialPoints: {
    pt: 'Pontuação final deve ser maior que pontuação inicial',
    en: 'Final points must be greater than initial points',
    es: 'Puntos finales deben ser mayores que puntos iniciales',
  },
  pointsMustBeGreaterThanLast: {
    pt: 'Pontuação deve ser maior que a última pontuação',
    en: 'Points must be greater than the last',
    es: 'Puntos deben ser mayores que los últimos',
  },
  fill_all_fields: {
    pt: 'Preencha todos os campos',
    en: 'Fill all fields',
    es: 'Llenar todos los campos',
  },
  fill_chapter_name_field: {
    pt: 'Preencha o nome do capítulo',
    en: 'Fill in the chapter name',
    es: 'Complete el nombre del capítulo',
  },
  fill_chapter_short_name_field: {
    pt: 'Preencha a sigla do capítulo',
    en: 'Fill in the acronym of the chapter',
    es: 'Complete el acrónimo del capítulo',
  },
  fill_certificate_fields: {
    pt: 'Preencha a seção "Certificados" para salvar',
    en: 'Fill the "Certificates" section to save',
    es: 'Llenar la sección "Certificados" para guardar',
  },
  fill_structure_fields: {
    pt: 'Preencha a seção "Estrutura" para salvar',
    en: 'Fill the "Structure" section to save',
    es: 'Llenar la sección "Estructura" para guardar',
  },
  technical_analysis_default_text: {
    pt: 'A análise técnica não foi descrita até o momento',
    en: 'Technical analysis has not been described so far',
    es: 'El análisis técnico no se ha descrito hasta ahora.',
  },
};

export default validationMessages;
