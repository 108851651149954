export const defaultMessages = {
  // SIMPLE MESSAGES
  success: {
    pt: 'Sucesso',
    en: 'Success',
    es: 'Éxito',
  },
  error: {
    pt: 'Erro',
    en: 'Error',
    es: 'Erro',
  },
  empty: {
    pt: 'Nenhum',
    en: 'None',
    es: 'Ninguna',
  },
  welcome: {
    pt: 'Bem vindo',
    en: 'Welcome',
    es: 'Bienvenido',
  },

  // EMPTY MESSAGES
  no_indicators: {
    pt: 'Sem indicadores',
    en: 'No indicators',
    es: 'Sin indicadores',
  },
  no_chapters: {
    pt: 'Sem capítulos',
    en: 'No chapters',
    es: 'Sin capítulos',
  },
  no_categories: {
    pt: 'Sem categorias',
    en: 'No categories',
    es: 'Sin categorias',
  },
  no_records: {
    pt: 'Sem registros',
    en: 'No records',
    es: 'Sin registros',
  },
  no_contact: {
    pt: 'Sem contatos',
    en: 'No contacts',
    es: 'Sin contactos',
  },
  no_enterprises: {
    pt: 'Sem empreendimentos',
    en: 'No enterprises',
    es: 'Sin emprendimientos',
  },
  no_company: {
    pt: 'Sem empresas',
    en: 'No companies',
    es: 'Sin compañías',
  },
  no_responsible: {
    pt: 'Sem responsável',
    en: 'No responsible',
    es: 'Sin responsable',
  },
  no_sub_functions: {
    pt: 'Não há sub-funções',
    en: 'There are no sub-functions',
    es: 'No hay subfunciones',
  },
  no_projects: {
    pt: 'Não há projetos',
    en: 'No projects',
    es: 'Sin proyectos',
  },
  no_services: {
    pt: 'Não há serviços',
    en: 'No services',
    es: 'Sin servicios',
  },
  no_status: {
    pt: 'Não há status',
    en: 'No status',
    es: 'No hay status',
  },
  no_functions: {
    pt: 'Não há funções',
    en: 'There are no functions',
    es: 'No hay funciones',
  },
  empty_indicators: {
    pt: 'Não possui indicadores cadastrados',
    en: 'Does not have registered indicators',
    es: 'No tiene indicadores registrados',
  },
  empty_message_action_plan: {
    pt: 'Não possui plano de ação cadastrado',
    en: 'No action plan registered',
    es: 'Ningún plan de acción registrado',
  },
  empty_message: {
    pt: 'Não possui item cadastrado',
    en: 'No item registered',
    es: 'Ningún artículo registrado',
  },
  empty_files: {
    pt: 'Nenhum arquivo cadastrado',
    en: 'No files',
    es: 'No hay archivos registrados',
  },
  empty_register: {
    pt: 'Nenhum registro cadastrado',
    en: 'No records',
    es: 'No hay registros registrados',
  },
  no_columns_created: {
    pt: 'Nenhum campo cadastrado',
    en: 'No fields created',
    es: 'No hay campos registrados',
  },
  no_formOfService_selected: {
    pt: 'Nenhuma forma de atendimento selecionada',
    en: 'No form of service selected',
    es: 'Ninguna forma de servicio seleccionada',
  },
  not_defined: {
    pt: 'Não definido',
    en: 'Not defined',
    es: 'No definido',
  },
  comment_deleted: {
    pt: 'Comentário excluído',
    en: 'Comment deleted',
    es: 'Comentario eliminado',
  },

  // NOT FOUND MESSAGES
  columns_not_found: {
    pt: 'Campos não encontrados',
    en: 'Fields not found',
    es: 'Campos no encontrados',
  },
  empty_message_filtered: {
    pt: 'Nenhum item encontrado',
    en: 'No items found',
    es: 'No se encontraron artículos',
  },
  status_not_found: {
    pt: 'Status não encontrado',
    en: 'Status not found',
    es: 'Status no encontrado',
  },
  service_not_found: {
    pt: 'Serviço não encontrado',
    en: 'Service not found',
    es: 'Servicio no encontrado',
  },
  projects_not_found: {
    pt: 'Projetos não encontrados',
    en: 'Projects not found',
    es: 'Proyectos no encontrados',
  },
  companies_not_found: {
    pt: 'Empresas não encontradas',
    en: 'Companies not found',
    es: 'Compañías no encontradas',
  },
  forms_of_service_not_found: {
    pt: 'Formas de atendimento não encontradas',
    en: 'Types of attention not found',
    es: 'Formas de servicio no encontradas',
  },

  // ERROR MESSAGES
  notification_config_add: {
    pt: 'Configuração salva com sucesso',
    en: 'Configuration saved successfully',
    es: 'Configuración guardada exitosamente',
  },
  notification_config_add_error: {
    pt: 'Erro na adição da configuração',
    en: 'Error in adding the configuration',
    es: 'Error al agregar la configuración',
  },
  deleted_error: {
    pt: 'Erro na remoção',
    en: 'Removal error',
    es: 'Error de eliminación',
  },
  auto_save_error: {
    pt:
      'Não foi possível salvar automaticamente devido a campos obrigatórios pendentes',
    en: 'Unable to auto-save due to pending required fields',
    es:
      'No se puede guardar automáticamente debido a campos obligatorios pendientes',
  },
  get_project_error: {
    pt: 'Houve um erro ao tentar capturar os arquivos.',
    en: 'There was an error trying to capture the files.',
    es: 'Hubo un error al intentar capturar los archivos.',
  },
  create_project_error: {
    pt: 'Houve um erro no cadastro do projeto.',
    en: 'There was an error in the project registration.',
    es: 'Hubo un error en el registro del proyecto.',
  },
  error_analysis_exclude_message: {
    pt: 'Não foi possível excluir análise',
    en: 'Unable to delete analysis',
    es: 'No se puede eliminar el análisis',
  },
  error_analysis_approve_message: {
    pt: 'Não foi possível aprovar análise',
    en: 'Unable to approve review',
    es: 'No se puede aprobar la revisión',
  },
  error_comment_approve_message: {
    pt: 'Não foi possível aprovar commentario',
    en: 'Unable to approve comment',
    es: 'No se puede aprobar el comentario',
  },
  service_completed_error: {
    pt: 'Erro no preenchimento do serviço',
    en: 'Error completing the service',
    es: 'Error al completar el servicio',
  },

  update_comment_error: {
    pt: 'Erro na atualização do comentário',
    en: 'Comment update error',
    es: 'Error de actualización de comentarios',
  },
  create_comment_error: {
    pt: 'Erro na criação do comentário',
    en: 'Error creating comment',
    es: 'Error al crear comentario',
  },

  // DESCRIPTIONS MESSAGES
  repeat_new_password: {
    pt: 'Repetir nova senha',
    en: 'Repeat new password',
    es: 'Repita la nueva contraseña',
  },
  rule_and_or: {
    pt: '(Regra E) ou (Regra OU)?',
    en: '(Rule AND) or (Rule OR)?',
    es: '¿(Regla Y) o (Regla O)?',
  },
  use_expanded_screen: {
    pt: 'Use tela expandida (Power BI)',
    en: 'Use expanded screen (Power BI)',
    es: 'Usar pantalla ampliada (Power BI)',
  },
  dragger_file_text: {
    pt: 'Clique ou arraste um arquivo para esta área',
    en: 'Click or drag an file to this area',
    es: 'Clic o arrastre un archivo a esta área',
  },
  dragger_text: {
    pt: 'Clique ou arraste uma imagem para esta área',
    en: 'Click or drag an image to this area',
    es: 'Clic o arrastre una imagen a esta área',
  },
  carbon_emission_per_area: {
    pt: 'Emissão de CO2eq por área (kgCO2eq /m²)',
    en: 'CO2eq emission per area (kgCO2eq /m²)',
    es: 'Emisión de CO2eq por área (kgCO2eq / m²)',
  },
  percentage_of_carbon_emission_reduction: {
    pt: '% de redução nas emissões de CO2eq',
    en: '% reduction in CO2eq emissions',
    es: '% de reducción de emisiones de CO2eq',
  },
  construction_waste_indicators: {
    pt: 'Indicadores de resíduos de obra',
    en: 'Construction waste indicators',
    es: 'Indicadores de residuos de construcción',
  },
  construction_waste_quantity_per_area: {
    pt: 'Quantidade de resíduos de obra gerado por área (kg/m²)',
    en: 'Amount of construction waste generated per area (kg/m²)',
    es: 'Cantidad de residuos de construcción generados por área (kg / m²)',
  },
  percentage_of_construction_waste_diverted: {
    pt: '% de resíduo de obra desviado de aterro',
    en: '% of construction waste diverted from landfill',
    es: '% de residuos de la construcción desviados del vertedero',
  },
  percentage_of_waste_generated_on_site_by_type: {
    pt: '% de resíduo gerado em obra por tipo',
    en: '% of waste generated on site by type',
    es: '% de residuos generados en el sitio por tipo',
  },
  percentage_of_reduction_of_energy_consumption_in_kwh: {
    pt: '% de redução do consumo anual de energia (%)',
    en: '% of annual reduction of energy consumption (%)',
    es: '% de reducción anual del consumo de energía (%)',
  },
  percentage_of_reduction_of_energy_consumption_in_cash: {
    pt: '% de redução do custo anual de energia (R$)',
    en: '% of annual energy cost reduction (R$)',
    es: '% de reducción anual del costo de energía (R$)',
  },
  installed_thermal_capacity: {
    pt: 'Capacidade térmica instalada (TR/m²)',
    en: 'Installed thermal capacity (TR/m²)',
    es: 'Capacidad térmica instalada (TR/m²)',
  },
  percentage_of_energy_consumption_by_source: {
    pt: '% de consumo de energia por fonte',
    en: '% of energy consumption by source',
    es: '% del consumo de energía por fuente',
  },
  eui: {
    pt: 'EUI - intensidade de consumo de energia (kWh/m²)',
    en: 'EUI - energy consumption intensity (kWh/m²)',
    es: 'EUI - intensidad de consumo de energía (kWh/m²)',
  },
  percentage_of_reduction_in_water_consumption_for_sanitary: {
    pt: '% de redução do consumo de água potável para dispositivos sanitários',
    en: '% of reduction in water consumption for sanitary devices',
    es:
      '% de reducción del consumo de agua potable para dispositivos sanitarios',
  },
  percentage_of_non_potable_water_consumption: {
    pt: '% de consumo de água não potável',
    en: '% of non potable water consumption',
    es: '% del consumo de agua no potable',
  },
  percentage_of_reduction_in_total_water_consumption: {
    pt: '% de redução do consumo de água total',
    en: '% of reduction in total water consumption',
    es: '% de reducción del consumo total de agua',
  },
  percentage_of_efficiency_of_the_irrigation_system: {
    pt: '% de eficiência do sistema de irrigação',
    en: '% of the irrigation system efficiency',
    es: '% de eficiencia del sistema de riego',
  },
  percentage_of_water_consumption_by_source: {
    pt: '% de consumo de água por fonte',
    en: '% of water consumption by source',
    es: '% de consumo de agua por fuente',
  },
  comparison: {
    pt: 'Meta de melhores práticas deve ser maior que a Meta de boas práticas',
    en: 'Better practice goal should be greater than good practice goal',
    es:
      'El objetivo de las mejores prácticas debe ser mayor que el objetivo de las buenas prácticas',
  },
  description_suport: {
    pt:
      'Caso tenha alguma dúvida ou precise de suporte, entre em contato pelo email:',
    en:
      'If you have any questions or need support, please contact us by email:',
    es:
      'Si tiene alguna pregunta o necesita ayuda, contáctenos por correo electrónico:',
  },
  attended_description: {
    pt:
      'Atendido, considerando a documentação disponível ou a sua efetiva implantação in loco.',
    en:
      'Attended, considering the available documentation or its implantation in loco.',
    es:
      'Atendido, considerando la documentación disponible o su efectiva implantación in loco.',
  },
  intervention_level_one_description: {
    pt:
      'Ações menos complexas ou previstas para o empreendimento, necessitando tecnologias e investimentos mais acessíveis.',
    en:
      'Less complex or planned actions for the enterprise, requiring cheaper technologies and less investments.',
    es:
      'Acciones menos complejas o planejadas para el emprendimiento, que requieren tecnologías e inversiones más accesibles.',
  },
  intervention_level_two_description: {
    pt:
      'Ações mais complexas, necessitando os estudos de viabilidade técnica e econômica.',
    en:
      'More complex actions, requiring technical and economic feasibility studies.',
    es:
      'Actuaciones más complejas, que requieren estudios de viabilidad técnica y económica.',
  },
  not_attended_description: {
    pt:
      'As estratégias necessárias para atendimento do crédito não foram e não serão incorporadas.',
    en:
      'The strategies required to meet credit were not and will not be incorporated.',
    es:
      'Las estrategias requeridas para cumplir con el crédito no fueron y ni serán incorporadas.',
  },
  not_pleated_description: {
    pt:
      'As estratégias necessárias para o atendimento dos requisitos não foram estabelecidas como meta para o projeto.',
    en:
      'The necessary strategies to meet the requirements were not established as a goal for the project.',
    es:
      'Las estrategias necesarias para cumplir con los requisitos no se establecieron como meta del proyecto.',
  },

  cte_analysis_placeholder: {
    pt:
      'Neste campo, deve ser preenchido com breves detalhes da revisão sobre a certificação neste empreendimento feita durante a criação da certificação ',
    en:
      'This field must be filled in with brief details of the review about the certification in this enterprise made during the creation of the certification ',
    es:
      'Este campo debe completarse con breves detalles de la revisión sobre la certificación en esta empresa realizada durante la creación de la certificación',
  },

  clear_all_notifications: {
    pt: 'Todas as notificações foram apagadas com sucesso.',
    en: 'All notifications have been successfully deleted.',
    es: 'Todas las notificaciones se han eliminado correctamente.',
  },

  error_clear_all_notifications: {
    pt: 'Erro ao limpar todas as notificações.',
    en: 'Error deleting all notifications.',
    es: 'Error al eliminar todas las notificaciones.',
  },
};

export default defaultMessages;
