import defaultMessages from './defaultMessages';
import actions from './actions';
import generalLabel from './generalLabel';
import status from './status';
import properties from './properties';
import address from './address';
import validationHelper from './validationHelper';
import common from './common';
import newItems from './newItems';
import successMessages from './successMessages';
import validationMessages from './validationMessages';
import confirmationMessages from './confimationMessages';
import indicatorsLabel from './indicatorsLabel';
import responsibleFilter from './responsibleFilter';

export const pageLanguage = {
  ...successMessages,
  ...confirmationMessages,
  ...validationMessages,
  ...defaultMessages,
  ...actions,
  ...common,
  ...responsibleFilter,
  ...newItems,
  ...generalLabel,
  ...indicatorsLabel,
  ...status,
  ...properties,
  ...address,
  ...validationHelper,
};
