/*eslint no-unused-expressions: off*/
import React, { createContext, FunctionComponent, useState } from 'react';

const ProjectContext = createContext<{
  page: number;
  setPage(perPage: number): void;
  perPage: number;
  setPerPage(perPage: number): void;
  name: string;
  setName(name: string): void;
  status: string;
  setStatus(name: string): void;
}>({
  page: 1,
  setPage: () => null,
  perPage: 12,
  setPerPage: () => null,
  name: '',
  setName: () => null,
  status: '',
  setStatus: () => null,
});

const ProjectProvider: FunctionComponent = ({ children }) => {
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(12);
  const [name, setName] = useState<string>('');
  const [status, setStatus] = useState<string>('');

  return (
    <ProjectContext.Provider
      value={{
        perPage,
        setPerPage,
        page,
        setPage,
        name,
        setName,
        status,
        setStatus,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export { ProjectContext, ProjectProvider };
