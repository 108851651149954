/*eslint no-unused-expressions: off*/
import React, {
  createContext,
  FunctionComponent,
  useState,
  useEffect,
} from 'react';

const LayoutContext = createContext<{
  isMobileView: boolean;
}>({
  isMobileView: false,
});

const LayoutProvider: FunctionComponent<{}> = ({ children }) => {
  const [isMobileView, setIsMobileView] = useState<boolean>(false);

  const checkMobileView = (load = false) => {
    const currentState = window.innerWidth < 768;

    if ((currentState && !isMobileView) || load) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener('resize', () => checkMobileView());
  }, []);

  return (
    <LayoutContext.Provider
      value={{
        isMobileView,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export { LayoutContext, LayoutProvider };
